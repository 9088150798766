import { Component } from '@angular/core';

@Component({
    selector: 'app-all-clients',
    templateUrl: './all-clients.component.html',
    styleUrls: ['./all-clients.component.scss'],
})
export class AllClientsComponent {
    isAllClientsDisplayed = false;

    clients: Array<object> = [
        {
            img: 'assets/clients/logo_ALBA_kolor_2014.jpg',
            alt: 'ALBA',
        },
        {
            img: 'assets/clients/uniqa.png',
            alt: 'Uniqa',
        },
        {
            img: 'assets/clients/schindler.jpg',
            alt: 'Schindler',
        },
        {
            img: 'assets/clients/logo Lift system.png',
            alt: 'Lift Systems',
        },
        {
            img: 'assets/clients/SMWP_wybrane.jpg',
            alt: 'SM Wrocław-Południe',
        },
        {
            img: 'assets/clients/msme-logo.png',
            alt: 'MSM Energetyk',
        },
        {
            img: 'assets/clients/domicilium_logojpg.jpg',
            alt: 'Domicilium',
        },
        {
            img: 'assets/clients/smcentrum logo.png',
            alt: 'SM Centrum',
        },
        {
            img: 'assets/clients/smczyzyny logo.png',
            alt: 'SM Czyżyny',
        },
        {
            img: 'assets/clients/rsm armatury logo.png',
            alt: 'RSM Armatury',
        },
        {
            img: 'assets/clients/osm.png',
            alt: 'OSM Przyszłość',
        },
        {
            img: 'assets/clients/lsm logo.png',
            alt: 'LSM',
        },
        {
            img: 'assets/clients/rsmbawelna.png',
            alt: 'RSM Bawełna',
        },
        {
            img: 'assets/clients/sieradz logo.jpg',
            alt: 'Sieradzka SM',
        },
        {
            img: 'assets/clients/4. Logo S.M. Jutrzenka.jpg',
            alt: 'SM Jutrzenka',
        },
        {
            img: 'assets/clients/zacisze logo.jpg',
            alt: 'SM Zacisze',
        },
        {
            img: 'assets/clients/smjaroslaw logo.png',
            alt: 'SM w Jarosławiu',
        },
        {
            img: 'assets/clients/gsm logo.jpg',
            alt: 'Górnicza SM',
        },
        {
            img: 'assets/clients/zatorze logo.jpg',
            alt: 'Zatorze',
        },
        {
            img: 'assets/clients/dobrzec.svg',
            alt: 'SM Dobrzec',
        },
        {
            img: 'assets/clients/otwocka.jpg',
            alt: 'Otwocka SM',
        },
        {
            img: 'assets/clients/wroclawski-dom.png',
            alt: 'Wrocławski Dom',
        },
        {
            img: 'assets/clients/rolbud.jpg',
            alt: 'SM Rolbud',
        },
        {
            img: 'assets/clients/smsprasa.jpg',
            alt: 'SM Śródmieście-Prasa',
        },
    ];
}
