<header>
    <section>
        <app-logo></app-logo>
    </section>
    <section>
        <nav>
            <ul>
                <li class="hide-on-mobile">
                    <a id="phone-header" href="tel:{{ businessNumber }}">{{
                        businessNumber
                    }}</a>
                </li>
                <li class="hide-on-mobile">
                    <a
                        id="facebook"
                        href="https://www.facebook.com/iMieszkaniec"
                        target="_blank"
                    >
                        Facebook
                    </a>
                </li>
                <li>
                    <a class="btn btn-green" routerLink="/login">
                        {{ 'login' | translate }}
                    </a>
                </li>
            </ul>
        </nav>
    </section>
</header>
