<section>
    <h2>{{ 'media_about_us' | translate }}</h2>

    <div class="clients">
        <div *ngFor="let item of images">
            <a href="{{ item.link }}" target="_blank">
                <img src="{{ item.url }}" alt="{{ item.alt }}" />
            </a>
        </div>
    </div>
</section>
