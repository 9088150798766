import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader } from '@ngx-translate/core';

function translateLoaderFactory(http: HttpClient): TranslateLoader {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export let translateLoaderProvider = {
    provide: TranslateLoader,
    useFactory: translateLoaderFactory,
    deps: [HttpClient],
};
