import { Injectable } from '@angular/core';

@Injectable()
export class ContactService {
    getBusinessContactNumber(): string {
        return '+48 720 754 264';
    }

    displayHelpDesk(): boolean {
        return true;
    }
}
