<app-header></app-header>
<main>
    <h1>Polityka Prywatności</h1>

    <section>
        <h4>Spis treści:</h4>
        <ul>
            <li>1.-5. Zagadnienia wprowadzające</li>
            <li>6. Przetwarzanie danych osobowych w Usłudze iMieszkaniec.pl</li>
            <li>
                7. Przetwarzanie danych osobowych Mieszkańca (Użytkownika) w
                Usłudze iMieszkaniec.pl
            </li>
            <li>
                8. Przetwarzanie danych osobowych Wykonawcy w Usłudze
                iMieszkaniec.pl
            </li>
            <li>
                9. Przetwarzanie danych osobowych w przypadku powiązania Usługi
                iMieszkaniec.pl z Usługą iWykonawca.pl
            </li>
            <li>
                10. Przetwarzanie danych osobowych Usługobiorcy (nazywany też w
                umowie Zamawiającym), który jest osobą fizyczną lub
                przedsiębiorcą będącym "osobą, której dane dotyczą"
            </li>
            <li>11. Informacja o cookies</li>
            <li>12. Definicje pojęć</li>
            <li>13. Zmiany w Polityce prywatności</li>
        </ul>
    </section>

    <section>
        <h4>
            1. Usługa iMieszkaniec.pl, to działanie podejmowane przez Operatora,
            przy wykorzystaniu Oprogramowania iMieszkaniec.pl, poprzez
            dedykowaną stronę internetową lub dedykowaną aplikację mobilną,
            świadczona na zasadach i warunkach określonych w Regulaminie Usługi
            iMieszkaniec.pl, zwanym dalej Regulaminem.
        </h4>
        <h4>
            2. Udostępnienie Usługi iMieszkaniec.pl następuje po dokonaniu
            rejestracji Użytkownika zgodnie z §6 Regulaminu. Mieszkaniec ma
            możliwość dokonania rejestracji samodzielnie, po wybraniu Operatora
            na stronie https://imieszkaniec.pl/logowanie i przejściu do strony
            rejestracji dedykowanej dla tego Operatora. Zarejestrowanie
            Wykonawcy może nastąpić wyłącznie bezpośrednio przez Operatora lub
            uprawnionego pracownika Operatora.
        </h4>
        <h4>3. Administratorzy Usługi iMieszkaniec.pl:</h4>
        <ul>
            <li>
                1) Administratorem zarządzającym Usługą iMieszkaniec.pl jest
                Operator, którym w szczególności jest spółdzielnia, wspólnota
                mieszkaniowa, TBS, zarządca nieruchomości lub inny właściwy
                podmiot. Administrator zarządzający nie jest tożsamym pojęciem z
                ADO.
            </li>

            <li>
                2) Administrator techniczny Usługi iMieszkaniec.pl sp. z o.o.
                wykonuje zlecone przez Operatora czynności obsługowe i nadzoruje
                techniczne funkcjonowanie świadczenia Usługi iMieszkaniec.pl
                (Oprogramowanie iMieszkaniec.pl i hosting serwerów).
            </li>
        </ul>
        <h4>
            4. Usługa iMieszkaniec.pl jest świadczona przez Operatora, w
            szczególności w celu zaspokojenia określonych potrzeb informacyjnych
            i komunikacyjnych Użytkowników, np. Mieszkańca, Wykonawcy, Operatora
            i jego pracownika w określonym dla tej Usługi funkcjonalnym zakresie
            oraz usprawnienia procesu wymiany informacji, dokumentowania,
            nadzoru i zarządzania w związku z obsługą Zgłoszeń.
        </h4>
        <h4>5. Korzystanie z Usługi iMieszkaniec.pl</h4>
        <ul>
            <li>
                1) Użytkownikiem Usługi iMieszkaniec.pl może zostać każda osoba,
                w szczególności Mieszkaniec, Wykonawca i pracownik, po dokonaniu
                rejestracji i aktywacji w Usłudze iMieszkaniec.pl oraz
                weryfikacji potwierdzonej przez właściwego Operatora. Doraźnie
                Użytkownikiem jest osoba korzystająca z Usługi iMieszkaniec.pl
                na zasadach określonych w rdz IV Regulaminu.
            </li>
            <li>
                2) Użytkowanie iMieszkaniec.pl przez Użytkownika jest bezpłatne.
            </li>
            <li>
                3) Do poprawnego działania iMieszkaniec.pl wymagana jest
                przeglądarka internetowa akceptująca pliki cookies lub
                dedykowana aplikacja mobilna.
            </li>
            <li>
                4) Regulamin korzystania z Usługi iMieszkaniec.pl (zwany dalej
                Regulamin) i Polityka prywatności oraz dane o indywidualnie
                określonym Operatorze dostępne są na stronie na stronie
                https://imieszkaniec.pl/login i przejściu do zakładki
                "Zarejestruj się" dedykowanej dla tego Operatora Usługi
                iMieszkaniec.pl.
            </li>
        </ul>
        <h4>6. Przetwarzanie danych osobowych w Usłudze iMieszkaniec.pl</h4>
        <ul>
            <li>
                1) Dane osobowe Użytkowników są przetwarzane z zachowaniem
                środków bezpieczeństwa spełniających wymagania RODO.
            </li>
            <li>
                2) Informacja o Administratorze Danych Osobowych (zwanym dalej
                Administratorem lub ADO) podana jest w Klauzuli informacyjnej
                Usługi iMieszkamiec.pl na stronie rejestracji Mieszkańca,
                (Użytkownika) do Usługi. Informacja o Administratorze i dane
                kontaktowe dla Wykonawcy podane są w linku w e-mailu do strony
                aktywacji konta zatrudnionego i Wykonawcy, (Użytkownika), w
                linku w e-mailu udostępnień Wykonawcy pojedynczego doraźnego
                zgłoszenia, a także w linku w e-mailu do powiązania z kontem
                Wykonawcy zewnętrznego w Usłudze iWykonawca.pl;
            </li>
            <li>
                3) Użytkownik loguje się do swojego konta w panelu logowania
                https://imieszkaniec.pl/login, wyłącznie w przypadku wybrania
                przez Użytkownika właściwej dla niego lokalizacji oraz
                właściwego dla niego Operatora o określonej skrótowej nazwie, od
                którego wcześniej uzyskał autoryzację.
            </li>
            <li>
                4) Administrator jako Operator oraz Operator jako działający w
                imieniu Administratora wykorzystując pełną funkcjonalność
                Oprogramowania iMieszkaniec.pl, przetwarza dane osobowe
                Użytkowników na zasadach określonych w niniejszej Polityce
                bezpieczeństwa w punktach poniżej oraz Regulaminie Usługi
                iMieszkaniec.pl.
            </li>
            <li>
                5) Jeżeli wystąpią jakiekolwiek rozbieżności pomiędzy niniejszą
                Polityką prywatności a Regulaminem usługi iMieszkaniec.pl, to w
                sprawach przetwarzania danych osobowych i definicjach, wiążące
                są zapisy Polityki prywatności.
            </li>
            <li>
                6) Jeżeli indywidualnie określony Operator (będący ADO lub
                działający w imieniu ADO) przedstawi odmienne niż w ustępach 7 i
                8 informacje oraz standardowe, cele i zasady przetwarzania
                danych osobowych Użytkowników w Usłudze iMieszkaniec.pl dla
                osób, których dane osobowe przetwarzane są w ramach Usługi, to
                te inne cele i zasady są wiążące. W takim przypadku standardowe
                cele i zasady podane w ust. 7 i 8 niniejszej Polityki
                bezpieczeństwa nie mają zastosowania.
            </li>
        </ul>
        <h4>
            7. Przetwarzanie danych osobowych Mieszkańca (Użytkownika) w Usłudze
            iMieszkaniec.pl
        </h4>
        <ul>
            <li>
                1) W Usłudze iMieszkaniec.pl dane osobowe Mieszkańca przetwarza
                / spółdzielnia / wspólnota mieszkaniowa, TBS / zarządca
                nieruchomości lub inny właściwy podmiot, który będąc Operatorem
                jako Administrator danych osobowych (ADO) lub w imieniu ADO
                przetwarza dane osobowe, za pomocą Usługi iMieszkaniec.pl.
            </li>
            <li>
                2) Operator, działając jako ADO lub w imieniu ADO na podstawie
                umowy zawartej z właściwym dla Mieszkańca ADO, przetwarza dane
                osobowe w ramach świadczonej Mieszkańcowi (Użytkownikowi) Usługi
                iMieszkaniec.pl, zgodnie z Klauzulą informacyjną. Klauzula
                informacyjna dostępna jest w panelu logowania
                https://imieszkaniec.pl/login, w przypadku wybrania przez
                Użytkownika lokalizacji oraz przypisanego do niej Operatora o
                określonej nazwie i przejściu do zakładki "Zarejestruj się"
                dedykowanej dla tego Operatora.
            </li>
            <li>
                3) W momencie zarejestrowania Mieszkańca jako Użytkownika Usługi
                przez Operatora będącego ADO lub działającego w imieniu ADO,
                zastosowanie mają standardowe informacje podane Mieszkańcowi w
                pkt. od 4 do 10 poniżej, z zastrzeżeniem w ust. 6. pkt. 5) i 6)
                niniejszej Polityki prywatności.
            </li>
            <li>
                4) Dane osobowe Mieszkańca w Usłudze iMieszkaniec.pl,
                realizowanej przy wykorzystaniu Oprogramowania iMieszkaniec.pl,
                przetwarzane będą w celu:
                <ul>
                    <li>
                        a - rejestracji, aktywacji i użytkowania konta
                        Mieszkańca (Użytkownika) w Usłudze iMieszkaniec.pl, na
                        zasadach określonych w jej Regulaminie na podstawie art.
                        6 ust. 1 lit. b) Ogólnego rozporządzenia o ochronie
                        danych tj. RODO (podjęcie działań na żądanie osoby,
                        której dane dotyczą, przed zawarciem umowy lub wykonanie
                        umowy);
                    </li>
                    <li>
                        b - przetwarzania danych osobowych Mieszkańca
                        (Użytkownika), które wprowadza w ramach konta
                        Użytkownika do przetwarzania w usłudze iMieszkaniec.pl,
                        w szczególności w związku z rejestrowaniem, wysyłaniem i
                        obsługą Zgłoszeń, korespondencją z Operatorem i
                        upoważnionymi Użytkownikami, wyrażaniem opinii w
                        głosowaniach spraw zarządczych (jeżeli Operator korzysta
                        z opcji głosowań), itp. na podstawie art. 6 ust.1 lit.
                        b) Ogólnego rozporządzenia o ochronie danych tj. RODO
                        (wykonanie umowy);
                    </li>
                    <li>
                        c - udostępniania Mieszkańcowi w koncie Użytkownika
                        informacji użytkowych, w szczególności o statusie
                        realizacji jego Zgłoszenia, odczytów i rozliczeń zużycia
                        mediów (jeżeli Operator z tej opcji korzysta), oraz
                        usprawniania procesu obsługi Mieszkańca i jego Zgłoszeń
                        w szczególności w obszarze komunikowania się i wymiany
                        informacji oraz dokumentowania i rozliczalności - na
                        podstawie art. 6 ust. 1 lit. b) Ogólnego rozporządzenia
                        o ochronie danych tj. RODO (wykonywanie umowy);
                    </li>
                    <li>
                        d - obsługi reklamacji, na podstawie art. 6 ust. 1 lit.
                        b) Ogólnego rozporządzenia o ochronie danych tj. RODO
                        (wykonanie umowy);
                    </li>
                    <li>
                        e - ustalenia i dochodzenia roszczeń lub dla obrony
                        przed takimi roszczeniami na podstawie art. 6 ust. 1
                        lit. f) Ogólnego rozporządzenia o ochronie danych tj.
                        RODO (prawnie uzasadniony interes Administratora, jakim
                        jest podejmowanie czynności związanych z roszczeniami).
                    </li>
                </ul>
            </li>
            <li>
                5) Odbiorcami danych osobowych Mieszkańca będą w szczególności:
                osoby zatrudnione, Wykonawcy (w tym Wykonawcy zewnętrzni) oraz
                inne podmioty, które uprawnione są przez Administratora lub
                działający w jego imieniu Podmiot przetwarzający do obsługi
                otrzymanego od Mieszkańca Zgłoszenia (np. awarii) i/lub obsługi
                pozostałych funkcjonalności udostępnionych Użytkownikowi w
                ramach jego Konta, iMieszkaniec.pl sp. z o.o. (dostawca
                Oprogramowania iMieszkaniec.pl) oraz jego podwykonawcy w związku
                ze świadczeniem usługi utrzymania i hostingowej, a także inne
                podmioty upoważnione do odbioru danych na podstawie umów oraz
                przepisów prawa.
            </li>
            <li>
                6) Dane osobowe Mieszkańca nie będą przekazywane do państwa
                trzeciego (tj. państwa nienależącego do Europejskiego Obszaru
                Gospodarczego) i/lub organizacji międzynarodowej.
            </li>
            <li>
                7) Okres przechowywania danych osobowych Mieszkańca
                zgromadzonych w Oprogramowaniu iMieszkaniec.pl uzależniony jest
                od celu, dla którego dane Mieszkańca są przetwarzane w ramach
                Usługi iMieszkaniec.pl, zgodnie z poniższymi kryteriami:
                <ul>
                    <li>
                        a) przez czas nie dłuższy niż okres użytkowania przez
                        Operatora Oprogramowania iMieszkaniec.pl (w celu
                        świadczenia Usługi iMieszkaniec.pl);
                    </li>
                    <li>
                        b) do momentu złożenia przez Mieszkańca uzasadnionego
                        sprzeciwu - w przypadku przetwarzania danych osobowych
                        na podstawie art. art. 6 ust. 1 lit. f);
                    </li>
                    <li>
                        c) przez okres wykonywania umowy tj. do momentu
                        usunięcia konta Mieszkańca na zasadach określonych w
                        Regulaminie Usługi iMieszkaniec.pl;
                    </li>
                    <li>
                        d) do momentu zakończenia obsługi reklamacji lub
                        rozstrzygnięcia sporu / rozliczenia stron, z
                        uwzględnieniem właściwych terminów przedawnienia
                        roszczeń – w przypadku przetwarzania danych w celu
                        dochodzenia roszczeń i podejmowania działań o
                        charakterze windykacyjnym jak również w celu obrony
                        przed roszczeniami.
                    </li>
                </ul>
                Po wskazanych wyżej okresach, dane osobowe przechowywane będą
                przez czas, jaki właściwe przepisy prawa nakazują przechowanie
                danych lub przez okres przedawnienia ewentualnych roszczeń.
            </li>
            <li>
                8) W związku z przetwarzaniem danych osobowych, Mieszkańcowi
                przysługują następujące prawa:
                <ul>
                    <li>
                        a) prawo dostępu do treści danych, prawo do sprostowania
                        danych, prawo do usunięcia danych - o ile nie występują
                        prawnie uzasadnione podstawy dalszego przetwarzania,
                        prawo do ograniczenia przetwarzania danych, prawo do
                        przenoszenia danych,
                    </li>
                    <li>
                        b) prawo do sprzeciwu wobec przetwarzania danych - w
                        przypadku, gdy przetwarzanie danych osobowych odbywa się
                        na podstawie tzw. prawnie uzasadnionego interesu i na
                        warunkach określonych w przepisach Ogólnego
                        rozporządzenia o ochronie danych,
                    </li>
                    <li>
                        c) prawo do wniesienia skargi do organu nadzorczego
                        (Prezesa Urzędu Ochrony Danych Osobowych) w przypadku
                        uznania, że przetwarzanie danych osobowych narusza
                        przepisy Ogólnego rozporządzenia o ochronie danych.
                    </li>
                </ul>
            </li>
            <li>
                9) Dane osobowe Mieszkańca nie podlegają zautomatyzowanemu
                podejmowaniu decyzji, w tym profilowaniu.
            </li>
            <li>
                10) Podanie przez Mieszkańca danych jest niezbędne do
                świadczenia usługi iMieszkaniec.pl, w przypadku niepodania
                danych niemożliwe jest korzystanie z tej Usługi.
            </li>
        </ul>
        <h4>
            8. Przetwarzanie danych osobowych Wykonawcy w Usłudze
            iMieszkaniec.pl, w tym w szczególności pracowników i osób
            zatrudnionych przez Administratora oraz dane osobowe pracowników i
            osób zatrudnionych przez przedsiębiorców i przedsiębiorców, będących
            osobami, których dane dotyczą, w związku ze świadczeniem dla
            Administratora usług przez przedsiębiorców, jako Wykonawców
            zewnętrznych.
        </h4>
        <ul>
            <li>
                1) W Usłudze iMieszkaniec.pl dane osobowe kategorii osób
                wymienionych w ust. 8, w zdaniu powyżej przetwarza /
                spółdzielnia / wspólnota mieszkaniowa, TBS / zarządca
                nieruchomości lub inny właściwy podmiot, który będąc Operatorem
                jako Administrator danych osobowych (ADO) lub w imieniu ADO
                przetwarza dane osobowe Wykonawcy, za pomocą realizowanej Usługi
                iMieszkaniec.pl.
            </li>
            <li>
                2) Nazwa Operatora działającego jako ADO lub w imieniu ADO oraz
                dane kontaktowe w celu uzyskana informacji w sprawach
                przetwarzania danych osobowych Wykonawcy w Usłudze
                iMieszkaniec.pl wskazane są:
                <ul>
                    <li>
                        - w linku w e-mailu do aktywacji konta Użytkownika
                        Usługi iMieszkaniec.pl jako Wykonawcy,
                    </li>
                    <li>
                        - w linku w e-mailu udostępnień Wykonawcy pojedynczego
                        doraźnego Zgłoszenia,
                    </li>
                    <li>
                        - w linku w e-mailu do uzyskania dostępu do panelu
                        zgłoszeń w Usłudze iMieszkaniec.pl i powiązania z kontem
                        Wykonawcy zewnętrznego w Usłudze iWykonawca.pl
                    </li>
                </ul>
                W odniesieniu do Wykonawcy zastosowanie mają standardowe
                informacje podane w pkt. od 3 do 9 poniżej, z zastrzeżeniem w
                ust. 6. pkt. 5) i 6) niniejszej Polityki prywatności..
            </li>
            <li>
                3) Dane osobowe Wykonawcy w Usłudze iMieszkaniec.pl, przy
                wykorzystaniu Oprogramowania iMieszkaniec.pl, przetwarzane będą
                w celu:
                <ul>
                    <li>
                        a) aktywacji i użytkowania w Usłudze iMieszkaniec.pl:
                        konta Wykonawcy (Użytkownika) albo powiązania z kontem
                        Wykonawcy zewnętrznego w Usłudze iWykonawca.pl na
                        zasadach określonych w Regulaminie, co następuje w
                        szczególności w związku z wykonywaniem wcześniej
                        zawartej innej umowy, np. o zatrudnienie lub świadczenie
                        Administratorowi usług, na podstawie art. 6 ust. 1 lit.
                        b) Ogólnego rozporządzenia o ochronie danych tj. RODO
                        (wykonanie umowy);
                    </li>
                    <li>
                        b - usprawnienia procesu obsługi Mieszkańca i Zgłoszeń,
                        w szczególności w obszarze komunikowania się oraz
                        wymiany informacji, (w tym o aktualnym stanie
                        realizacji), dokumentowania i rozliczalności art. 6 ust.
                        1 lit. b) Ogólnego rozporządzenia o ochronie danych tj.
                        RODO (wykonywanie umowy)
                    </li>
                    <li>
                        c) obsługi reklamacji Wykonawcy (Użytkownika), na
                        podstawie art. 6 ust. 1 lit. b) Ogólnego rozporządzenia
                        o ochronie danych tj. RODO (wykonanie umowy);
                    </li>
                    <li>
                        d) - usprawnienia procesu obsługi Mieszkańca i Zgłoszeń,
                        w szczególności w obszarze komunikowania się oraz
                        wymiany informacji, (w tym o aktualnym stanie
                        realizacji), dokumentowania i rozliczalności,
                        realizowanych bez wymogu akceptacji przez Wykonawcę
                        Regulaminu Usługi iMieszkaniec.pl lub ponad zakres
                        wskazany w Regulaminie zastosowanie ma art. 6 ust. 1 lit
                        f) Ogólnego rozporządzenia o ochronie danych tj. RODO
                        (prawnie uzasadniony interes, jakim jest usprawnienie
                        procesu obsługi Zgłoszeń)
                    </li>
                    <li>
                        e) - ustalenia i dochodzenia roszczeń lub dla obrony
                        przed takimi roszczeniami na podstawie art. 6 ust. 1
                        lit. f) Ogólnego rozporządzenia o ochronie danych tj.
                        RODO (prawnie uzasadniony interes Administratora, jakim
                        jest podejmowanie czynności związanych z roszczeniami).
                    </li>
                </ul>
            </li>
            <li>
                4) Odbiorcami danych osobowych Wykonawcy będą w szczególności:
                Mieszkaniec, podmioty świadczące usługi oraz osoby uprawnione
                przez Administratora lub działający w jego imieniu Podmiot
                przetwarzający w związku z wykonywaną zleconą obsługą
                otrzymanego Zgłoszenia, itp. (np. naprawy) i/lub obsługi
                pozostałych funkcjonalności udostępnionych Użytkownikowi w
                ramach jego Konta, iMieszkaniec.pl sp. z o.o. (dostawca
                Oprogramowania iMieszkaniec.pl) oraz jego podwykonawcy w związku
                ze świadczeniem usługi utrzymania i hostingowej, a także inne
                podmioty upoważnione do odbioru danych na podstawie przepisów
                prawa.
            </li>
            <li>
                5) Dane osobowe Wykonawcy nie będą przekazywane do państwa
                trzeciego (tj. państwa nienależącego do Europejskiego Obszaru
                Gospodarczego) i/lub organizacji międzynarodowej.
            </li>
            <li>
                6) Okres przechowywania danych osobowych Wykonawcy (Użytkownika)
                zgromadzonych i przetwarzanych w Oprogramowaniu iMieszkaniec.pl
                uzależniony jest od celu, dla którego dane są zbierane, zgodnie
                z poniższymi kryteriami:
                <ul>
                    <li>
                        a) przez czas nie dłuższy niż okres użytkowania przez
                        Operatora Oprogramowania iMieszkaniec.pl (w celu
                        świadczenia Usługi iMieszkaniec.pl);
                    </li>
                    <li>
                        b) do momentu usunięcia konta Wykonawcy (Użytkownika)
                        lub dezaktywacji powiązania z kontem w usłudze
                        iWykonawca.pl albo dezaktywacji udostępnionego Wykonawcy
                        pojedynczego doraźnego Zgłoszenia na zasadach
                        określonych w Regulaminie;
                    </li>
                    <li>
                        c) do momentu złożenia przez Wykonawcę uzasadnionego
                        sprzeciwu - w przypadku przetwarzania danych osobowych
                        na podstawie art. 6 ust. 1 lit. f);
                    </li>
                    <li>
                        d) do momentu zakończenia obsługi reklamacji lub
                        rozstrzygnięcia sporu / rozliczenia stron, z
                        uwzględnieniem właściwych terminów przedawnienia
                        roszczeń – w przypadku przetwarzania danych w celu
                        dochodzenia roszczeń i podejmowania działań o
                        charakterze windykacyjnym jak również w celu obrony
                        przed roszczeniami
                    </li>
                </ul>

                Po wskazanych wyżej okresach, dane osobowe przechowywane będą
                przez czas, jaki właściwe przepisy prawa nakazują przechowanie
                danych lub przez okres przedawnienia ewentualnych roszczeń.
            </li>
            <li>
                7) W związku z przetwarzaniem danych osobowych, Przysługują
                Wykonawcy następujące prawa:
                <ul>
                    <li>
                        a) prawo dostępu do treści danych, prawo do sprostowania
                        danych, prawo do usunięcia danych - o ile nie występują
                        prawnie uzasadnione podstawy dalszego przetwarzania,
                        prawo do ograniczenia przetwarzania danych, prawo do
                        przenoszenia danych,
                    </li>
                    <li>
                        b) prawo do uzasadnionego sprzeciwu wobec przetwarzania
                        danych - w przypadku, gdy przetwarzanie danych osobowych
                        odbywa się na podstawie tzw. prawnie uzasadnionego
                        interesu i na warunkach określonych w przepisach
                        Ogólnego rozporządzenia o ochronie danych,
                    </li>
                    <li>
                        c) prawo do wniesienia skargi do organu nadzorczego
                        (Prezesa Urzędu Ochrony Danych Osobowych) w przypadku
                        uznania, że przetwarzanie danych osobowych narusza
                        przepisy Ogólnego rozporządzenia o ochronie danych.
                    </li>
                </ul>
            </li>
            <li>
                8) Dane osobowe Wykonawcy nie podlegają zautomatyzowanemu
                podejmowaniu decyzji, w tym profilowaniu.
            </li>
            <li>
                9) Podanie przez Wykonawcę danych jest niezbędne do świadczenia
                usługi iMieszkaniec.pl, w przypadku niepodania danych niemożliwe
                jest korzystanie z tej Usługi.
            </li>
        </ul>
        <h4>
            9. Przetwarzanie danych osobowych w przypadku powiązania Usługi
            iMieszkaniec.pl z Usługą iWykonawca.pl
        </h4>
        <ul>
            <li>
                1) Usługa iMieszkaniec.pl może być powiązana z Usługą
                iWykonawca.pl na zasadach określonych w §12 Regulaminu.
            </li>
            <li>
                2) Przetwarzanie oraz udostępnianie danych i danych osobowych
                zawartych w Zgłoszeniu między określonym Operatorem Usługi
                iMieszkaniec.pl i powiązanym Użytkownikiem Usługi iWykonawca.pl
                odbywa się zależnie od stosunków prawnych, w szczególności w
                związku z wykonaniem umowy, oraz prawnie uzasadnionym interesem
                Administratora (ADO).
            </li>
            <li>
                3) W ramach powiązania Usług w celu przekazywania Zgłoszeń,
                indywidualnie wskazany Operator Usługi iMieszkaniec.pl pozostaje
                Administratorem danych osobowych (ADO) lub działa w imieniu, na
                zlecenie ADO.
            </li>
            <li>
                4) Dane osobowe Wykonawcy zewnętrznego (przedsiębiorcy),
                będącego osobą, której dane dotyczą, w powiązanej Usłudze są
                rejestrowane i przetwarzane przez Operatora w Usłudze
                iMieszkaniec.pl w takim samym zakresie jak Wykonawcy, który jest
                Użytkownikiem Usługi iMieszkaniec.pl, wskazane w §3 ust, 5 lit.
                c - iii Regulaminu.
            </li>
            <li>
                5) Dane osobowe Mieszkańca o których mowa w §3 ust.5 lit.c-i
                tiret 1 i 2 Regulaminu, udostępnione są Wykonawcy zewnętrznemu z
                Usługi iMieszkaniec.pl wyłącznie do odczytu, w związku z
                przekazanym jemu Zgłoszeniem do obsługi.
            </li>
            <li>
                6) Dane osobowe Mieszkańca o których mowa w §3 ust.5 lit.c-i
                tiret 1 i 2 oraz lit.c-ii tiret 1 Regulaminu, przyjęte przez
                Wykonawcę zewnętrznego (np. pogotowie techniczne) osobiście,
                telefonicznie, mailowo, itp. rejestrowane są i aktualizowane w
                Zgłoszeniu przez Wykonawcę w panelu Usługi iWykonawca.pl i
                jednocześnie zapisywane w powiązanej Usłudze iMieszkaniec.pl.
                Dane osobowe zgłaszającego Mieszkańca odnotowane w koncie
                Wykonawcy zewnętrznego ulegają anonimizacji poprzez usunięcie
                danych kontaktowych zgłaszającego po 90 dniach od oznaczenia
                Zgłoszenia jako zakończone.
            </li>
        </ul>
        <h4>
            10. Przetwarzanie danych osobowych Usługobiorcy (zwany też w umowie
            Zamawiającym), który jest osobą fizyczną lub przedsiębiorcą, będącym
            "osobą, której dane dotyczą".
        </h4>
        <ul>
            <li>
                1) Administratorem danych osobowych Usługobiorcy w związku z
                zawarciem, wykonywaniem umowy o udostępnienie Oprogramowania
                iMieszkaniec.pl oraz świadczeniem u użytkowaniem Usługi
                iMieszkaniec.pl, który jest osobą fizyczną lub przedsiębiorcą,
                będącym osobą, której dane dotyczą, jest iMieszkaniec.pl sp. z
                o.o., ul. Kordeckiego 18, 62-800 Kalisz, KRS: 0000789320, zwany
                dalej Administratorem, który przetwarza dane osobowe
                Usługobiorcy.
            </li>
            <li>
                2) Dane kontaktowe Administratora danych osobowych stanowią
                adres email: kontakt@imieszkaniec.pl.
            </li>
            <li>
                3) Administrator danych osobowych Usługobiorcy jest jednocześnie
                Usługodawcą udostępniającym na podstawie umowy do użytkowania
                Oprogramowanie iMieszkaniec.pl w celu umożliwienia dowolnemu
                Usługobiorcy, jako Operatorowi świadczenia Usługi
                iMieszkaniec.pl.
            </li>
            <li>
                4) Dane osobowe Usługobiorcy przetwarzane będą w celu:
                <ul>
                    <li>
                        a) zawarcia i wykonywania umowy, o udostępnienie do
                        użytkowania Oprogramowania iMieszkaniec.pl (....) na
                        podstawie art. 6 ust. 1 lit. b) Ogólnego rozporządzenia
                        o ochronie danych tj. RODO (podjęcie działań na żądanie
                        osoby, której dane dotyczą, przed zawarciem umowy lub
                        wykonanie umowy);
                    </li>
                    <li>
                        b) obsługi Usługobiorcy w związku z użytkowaniem
                        Oprogramowania iMiszkaniec.pl oraz świadczeniem przez
                        Usługobiorcę jako Operator Usługi iMieszkaniec.pl, w
                        szczególności w sprawach reklamacji, usuwania kont
                        Użytkowników, itp. na podstawie art. 6 ust. 1 lit. b)
                        Ogólnego rozporządzenia o ochronie danych tj. RODO
                        (wykonanie umowy);
                    </li>
                    <li>
                        c) obsługi w związku z aktywowaniem konta przez
                        Usługobiorcę, jako Użytkownika w ramach Usługi
                        iMieszkaniec.pl, na podstawie art. 6 ust. 1 lit. b)
                        Ogólnego rozporządzenia o ochronie danych tj. RODO
                        (wykonanie umowy),
                    </li>
                    <li>
                        d) prezentowania aktualnej oferty produktowej na
                        podstawie i ewentualnej komunikacji w zakresie
                        realizacji umowy art. 6 ust. 1 lit. f) Ogólnego
                        rozporządzenia o ochronie danych tj. RODO (obsługa
                        Usługobiorcy)
                    </li>
                    <li>
                        e) wypełnienie obowiązku prawnego ciążącego na
                        Administratorze, w szczególności wynikający z
                        powszechnie obowiązujących przepisów prawa o
                        rachunkowości i podatkowych na podstawie art. 6 ust. 1
                        lit. c) Ogólnego rozporządzenia o ochronie danych tj.
                        RODO; (wypełnienie obowiązku prawnego)
                    </li>
                    <li>
                        f) ustalenia i dochodzenia roszczeń lub dla obrony przed
                        takimi roszczeniami na podstawie art. 6 ust. 1 lit. f)
                        Ogólnego rozporządzenia o ochronie danych tj. RODO
                        (prawnie uzasadniony interes Administratora, jakim jest
                        podejmowanie czynności związanych z roszczeniami).
                    </li>
                </ul>
            </li>
            <li>
                5) Odbiorcami danych osobowych Usługobiorcy, którego dane
                dotyczą będą w szczególności podmioty przetwarzające w związku z
                obsługą finansową, komunikacją oraz zarządzaniem relacjami (CRM)
                z Usługobiorcą, podwykonawcy iMieszkaniec sp. z o.o. w zakresie
                świadczonych usług hostingowych i utrzymania, a także podmioty
                wskazane przez Operatora Usługi iMieszkaniec.pl w związku ze
                świadczeniem przez niego Usługi iMieszkaniec.pl, a także
                podmioty upoważnione do odbioru danych na podstawie umów lub
                przepisów prawa.
            </li>
            <li>
                6) Dane osobowe Usługobiorcy nie będą przekazywane do państwa
                trzeciego (tj. państwa nienależącego do Europejskiego Obszaru
                Gospodarczego) i/lub organizacji międzynarodowej.
            </li>
            <li>
                7) Okres przechowywania danych osobowych Usługobiorcy
                uzależniony jest od celu, dla którego dane są zbierane, zgodnie
                z poniższymi kryteriami:
                <ul>
                    <li>
                        a) przez czas nie dłuższy niż okres wykonywania umowy o
                        udostępnienie do użytkowania Oprogramowania
                        iMieszkaniec.pl;
                    </li>
                    <li>
                        b) do momentu złożenia przez Usługobiorcę uzasadnionego
                        sprzeciwu - w przypadku przetwarzania danych osobowych
                        na podstawie art. art. 6 ust. 1 lit. f);
                    </li>
                    <li>
                        c) do momentu usunięcia konta Usługobiorcy, jako
                        Użytkownika Usługi iMieszkaniec.pl (jeżeli takie konto
                        aktywował i użytkował);
                    </li>
                    <li>
                        d) do momentu zakończenia obsługi reklamacji, o ile nie
                        ma dalszych podstaw do przetwarzania
                    </li>
                    <li>
                        e) do momentu rozstrzygnięcia sporu / rozliczenia stron,
                        z uwzględnieniem właściwych terminów przedawnienia
                        roszczeń – w przypadku przetwarzania danych w celu
                        dochodzenia roszczeń i podejmowania działań o
                        charakterze windykacyjnym jak również w celu obrony
                        przed roszczeniami.
                    </li>
                </ul>
                Po wskazanych wyżej okresach, dane osobowe mogą być nadal
                przetwarzane przez czas, jaki właściwe przepisy prawa nakazują
                dalsze przechowanie danych i występują prawnie uzasadnione
                podstawy dalszego przetwarzania.
            </li>
            <li>
                8) W związku z przetwarzaniem danych osobowych Usługobiorcy
                przysługują następujące prawa:
                <ul>
                    <li>
                        a) prawo dostępu do treści danych, prawo do sprostowania
                        danych, prawo do usunięcia danych - o ile nie występują
                        prawnie uzasadnione podstawy dalszego przetwarzania,
                        prawo do ograniczenia przetwarzania danych, prawo do
                        przenoszenia danych,
                    </li>
                    <li>
                        b) prawo do sprzeciwu wobec przetwarzania danych - w
                        przypadku, gdy przetwarzanie danych osobowych odbywa się
                        na podstawie tzw. prawnie uzasadnionego interesu i na
                        warunkach określonych w przepisach Ogólnego
                        rozporządzenia o ochronie danych,
                    </li>
                    <li>
                        c) prawo do wniesienia skargi do organu nadzorczego
                        (Prezesa Urzędu Ochrony Danych Osobowych) w przypadku
                        uznania, że przetwarzanie danych osobowych narusza
                        przepisy Ogólnego rozporządzenia o ochronie danych.
                    </li>
                </ul>
            </li>
            <li>
                9) Dane osobowe Usługobiorcy nie podlegają zautomatyzowanemu
                podejmowaniu decyzji, w tym profilowaniu.
            </li>
            <li>
                10)Podanie przez Usługobiorcę danych osobowych jest niezbędne do
                świadczenia usługi iMieszkaniec.pl, w przypadku niepodania
                danych niemożliwe jest korzystanie z tej Usługi.
            </li>
        </ul>
        <h4>11. Informacja o cookies</h4>
        <ul>
            <li>
                1) Serwis Usługodawcy używa plików „ cookies” . Brak zmiany po
                stronie Usługobiorcy ustawień przeglądarki jest równoznaczny z
                wyrażeniem zgody na ich użycie.
            </li>
            <li>
                2) Instalacja plików „ cookies ” jest konieczna do prawidłowego
                świadczenia usług w Serwisie. W plikach „ cookies " znajdują się
                informacje niezbędne do prawidłowego funkcjonowania Serwisu, w
                szczególności tych wymagających autoryzacji.
            </li>
            <li>
                3) W ramach Serwisu stosowane pliki „ cookies” analityczne. „
                Cookies ” analityczne umożliwiają lepsze poznanie sposobu
                interakcji Usługobiorcy w zakresie zawartości Serwisu, lepiej
                zorganizować jego układ. “Analityczne” „ cookies ” gromadzą
                informacje o sposobie korzystania z Serwisu przez Usługobiorców,
                typie strony, z jakiej Usługobiorca został przekierowany, oraz
                liczbie odwiedzin i czasie wizyty Usługobiorcy na stronie
                serwisu. Informacje te nie rejestrują konkretnych danych
                osobowych Usługobiorcy, lecz służą do opracowania statystyk
                korzystania z Serwisu.
            </li>
            <li>
                4) W ramach serwisu stosowane są pliki „cookies” związane z
                analityką, pochodzące od Google, z którego usług serwis
                korzysta. Z uwagi na fakt, że sposób działania niektórych plików
                „cookies” pochodzących od partnera zewnętrznego może być inny,
                niż przedstawiony w niniejszej polityce, zalecane jest
                zapoznanie się z informacjami na temat tych plików „cookies”
                dostępnymi na stronach Google:
                https://www.google.pl/intl/pl/policies/technologies/cookies/
            </li>
            <li>
                5) Użytkownik ma prawo zadecydowania w zakresie dostępu plików „
                cookies ” do swojego komputera poprzez ich uprzedni wybór w
                oknie swojej przeglądarki. Szczegółowe informacje o możliwości i
                sposobach obsługi plików„ cookies ” dostępne są w ustawieniach
                oprogramowania (przeglądarki internetowej).
            </li>
        </ul>
        <h4>12. Definicje pojęć</h4>
        <ul>
            <li>
                1) <b>Usługa iMieszkaniec.pl</b> - działanie podejmowane przez
                Operatora, przy wykorzystaniu Oprogramowania iMieszkaniec.pl,
                poprzez dedykowaną stronęinternetową lub dedykowaną aplikację
                mobilną, wcelu zaspokojenia przez Operatora określonej potrzeby
                Mieszkańca (Użytkownika). Usługa zapewnia komunikację
                Użytkowników poprzez wysyłanie,odbieranie, rejestrowanie i
                przetwarzanie danych za pomocą systemów teleinformatycznych, o
                sprawach, zdarzeniach, zagrożeniach, problemach (np.: awarie,
                ograniczenia dostaw mediów, planowane zmiany w świadczeniu
                usług), głosowaniach, odczytach liczników, itp. Usługa nie
                obsługuje zgłoszeń alarmowych do Centrum powiadamiania
                ratunkowego, które jest dostępne telefonicznie po wybraniu
                numeru 112.
            </li>
            <li>
                2) <b>Oprogramowanie iMieszkaniec.pl</b> – system informatyczny
                udostępniony przez iMieszkaniec.pl sp. z o.o., w formie usługi
                SaaS , w celu umożliwienia Operatorowi świadczenia Usługi
                iMieszkaniec.pl za pośrednictwem przeglądarki internetowej lub
                dedykowanego oprogramowania urządzeń mobilnych.
            </li>
            <li>
                3) <b>SaaS</b> - („Software as a Service” czyli „Oprogramowanie
                jako usługa”) usługa, która polega na zdalnym udostępnianiu
                oprogramowania poprzez Internet. Umożliwia ona interakcję z
                systemem informatycznym poprzez interfejs przeglądarki
                internetowej.
            </li>
            <li>
                4) <b>Usługa iWykonawca.pl</b> - działanie podejmowane przez
                iMieszkaniec.pl sp. z o.o., przy wykorzystaniu Oprogramowania
                iWykonawca.pl, poprzez stronę internetową https://iwykonawca.pl
                lub aplikację mobilną, w celu zaspokojenia potrzeb
                komunikacyjnych Wykonawców w zakresie obsługi Zgłoszeń
                (związanych np. z zaistnieniem i usuwaniem awarii) oraz ich
                dokumentowania. Zgłoszenia te mogą być otrzymywane od
                spółdzielni, wspólnot mieszkaniowych, zarządców nieruchomości i
                innych podmiotów korzystających z Usługi iMieszkaniec.pl oraz
                spółdzielni, wspólnot mieszkaniowych, zarządców nieruchomości i
                innych podmiotów, które nie korzystają z Usługi iMieszkaniec.pl.
            </li>
            <li>
                5) <b>Administrator danych osobowych / Administrator</b> -
                oznacza osobę fizyczną lub prawną, organ publiczny, jednostkę
                lub inny podmiot, który samodzielnie lub wspólnie z innymi
                ustala cele i sposoby przetwarzania danych osobowych.
            </li>
            <li>
                6)<b>Aplikacja mobilna</b> – oprogramowanie działające na
                urządzeniach mobilnych, dostępne nieodpłatnie na platformach
                Google Play i App Store, pod nazwą “iMieszkaniec.pl”. Pełni ona
                funkcję rekomendowanego z uwagi na wygodę, opcjonalnego
                interfejsu Użytkownika, dedykowanego dla Usługi iMieszkaniec.pl.
                Aplikacja mobilna jest alternatywą dla korzystania z Usługi
                iMieszkaniec.pl za pomocą przeglądarek internetowych, jak np.:
                Google Chrome, Opera, Firefox, itp. w wersji mobilnej.
            </li>
            <li>
                7) <b>Cookies</b> – tzw. ciasteczka, czyli pliki tekstowe
                zapisywane automatycznie na dysku lokalnym komputera przez
                Przeglądarkę internetową i przechowujące informacje dotyczące
                odwiedzin takie jak np. typ Przeglądarki internetowej, itp.
            </li>
            <li>
                8) <b>Dane osobowe</b> - oznaczają informacje o zidentyfikowanej
                lub możliwej do zidentyfikowania osobie fizycznej („osobie,
                której dane dotyczą”).
            </li>
            <li>
                9) <b>Mieszkaniec</b> – osoba fizyczna, w tym także prowadząca
                działalność gospodarczą (przedsiębiorca), na rzecz której
                Administrator (w szczególności: spółdzielnia, wspólnota
                mieszkaniowa, TBS, zarządca lub inny właściwy podmiot) realizuje
                zadania związane z zarządzaniem nieruchomościami, zwany również
                Użytkownikiem. Mieszkańcem w ograniczonym, właściwym jej
                zakresie, jest również każda osoba wysyłająca zgłoszenie na
                zasadach określonych w rdz. IV pkt. 1a. i pkt. 2. Regulaminu.
            </li>
            <li>
                10) <b>Operator</b> - podmiot udostępniający Usługę
                iMieszkaniec.pl upoważnionym przez niego Użytkownikom, którym
                jest spółdzielnia / wspólnota mieszkaniowa, TBS, zarządca
                nieruchomości lub inny właściwy podmiot indywidualnie oznaczony,
                wskazany w procesie rejestrowania do Usługi.
            </li>
            <li>
                11) <b>Podmiot przetwarzający</b> - oznacza iMieszkaniec.pl sp.
                z o.o. na podstawie powierzenia do przetwarzania w określonym
                umową celu i zakresie.
            </li>
            <li>
                12) <b>Przetwarzanie danych osobowych</b> – w rozumieniu RODO
                „przetwarzanie” oznacza operację lub zestaw operacji
                wykonywanych na danych osobowych lub zestawach danych osobowych
                w sposób zautomatyzowany lub niezautomatyzowany, taką jak
                zbieranie, utrwalanie, organizowanie, porządkowanie,
                przechowywanie, adaptowanie lub modyfikowanie, pobieranie,
                przeglądanie, wykorzystywanie, ujawnianie poprzez przesłanie,
                rozpowszechnianie lub innego rodzaju udostępnianie,
                dopasowywanie lub łączenie, ograniczanie, usuwanie lub
                niszczenie.
            </li>
            <li>
                13) <b>Regulamin</b> - regulamin Usługi iMieszkaniec.pl,
                dostępny na stronie iMieszkaniec.pl, w panelu
                logowania/rejestrowania do Usługi
            </li>
            <li>
                14) <b>RODO</b> - Rozporządzenie Parlamentu Europejskiego i Rady
                (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób
                fizycznych w związku z przetwarzaniem danych osobowych i w
                sprawie swobodnego przepływu takich danych oraz uchylenia
                dyrektywy 95/46/WE (ogólne rozporządzenie o ochronie danych).
            </li>
            <li>
                15) <b>Strona Operatora</b> - strona internetowa Operatora
                umożliwiająca Użytkownikowi przejście do Usługi iMieszkaniec.pl.
            </li>
            <li>
                16) <b>Użytkownik</b> - osoba zalogowana do Usługi
                iMieszkaniec.pl, która ma założone i zatwierdzone przez
                indywidualnie oznaczonego Operatora konto. Użytkownikiem może
                być w szczególności Mieszkaniec zasobów mieszkaniowych
                zarządzanych przez Administratora (np. spółdzielni), osoba
                zatrudniona przez Administratora, Wykonawca lub inny podmiot,
                który w sposób autoryzowany korzysta z Systemu, po rejestracji
                do Usługi iMieszkaniec.pl. Użytkownikiem są również osoby
                korzystające z Usługi iMieszkaniec.pl na zasadach opisanych w
                rdz. IV Regulaminu.
            </li>
            <li>
                17) <b>Wykonawca</b> – osoba fizyczna, przedsiębiorca lub osoba
                prawna albo jednostka organizacyjna nie posiadająca osobowości
                prawnej, świadcząca usługi lub pracę na rzecz Administratora w
                związku z obsługą Zgłoszeń i innych funkcjonalności Usługi
                iMieszkaniec.pl, zwany również Użytkownikiem w dalszej części
                Regulaminu Usługi iMieszkaniec.pl, z zastrzeżeniem §12 ust. 3
                lit. a) Regulaminu.
            </li>
            <li>
                18)<b>Zgłoszenie</b> - zarejestrowane w iMieszkaniec.pl
                zdarzenie (o zaistnieniu, zleceniu i obsłudze np. awarii) w
                formie elektronicznej, którego dane przetwarzane są w Usłudze
                iMieszkaniec.pl, zgodnie z jej funkcjonalnością, potrzebami i
                uprawnieniami Użytkownika oraz w możliwym powiązaniu z Usługą
                iWykonawca.pl.
            </li>
        </ul>
        <h4>13. Zmiany w Polityce prywatności.</h4>
        <p>
            W celu zapewnienia, iż Polityka prywatności Portalu spełnia przez
            cały czas aktualne wymogi nałożone w przepisach prawa, Administrator
            techniczny zastrzega sobie prawo do wnoszenia do niej zmian w
            dowolnym czasie. Powyższe ma również zastosowanie w przypadkach, gdy
            Polityka prywatności wymaga zmian w związku z wprowadzeniem nowych
            lub zmienionych produktów albo usług. w określonym umową celu i
            zakresie. , który przetwarza dane osobowe Przetwarzanie danych
            osobowych – w rozumieniu RODO „przetwarzanie” oznacza operację lub
            zestaw operacji wykonywanych na danych osobowych lub zestawach
            danych osobowych w sposób zautomatyzowany lub niezautomatyzowany,
            taką jak zbieranie, utrwalanie, organizowanie, porządkowanie,
            przechowywanie, adaptowanie lub modyfikowanie, pobieranie,
            przeglądanie, wykorzystywanie, ujawnianie poprzez przesłanie,
            rozpowszechnianie lub innego rodzaju udostępnianie, dopasowywanie
            lub łączenie, ograniczanie, usuwanie lub niszczenie. Polityka
            Prywatności będzie obowiązywała w nowym brzmieniu od dnia
            powiadomienia o jej zmianie poprzez umieszczenie i udostępnienie
            Polityki prywatności w zaktualizowanej wersji na stronie logowania /
            rejestracji do Usługi iMieszkaniec.pl.
        </p>
    </section>
</main>
<app-footer></app-footer>
