<app-header></app-header>
<main>
    <app-contact-form></app-contact-form>
    <app-our-clients></app-our-clients>
    <app-content></app-content>
    <app-all-clients></app-all-clients>
    <app-reviews></app-reviews>
    <app-media></app-media>
    <app-call-us></app-call-us>
</main>
<app-footer></app-footer>
