import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-our-clients',
    templateUrl: './our-clients.component.html',
    styleUrls: ['./our-clients.component.scss'],
})
export class OurClientsComponent implements OnInit {
    public options: object = {
        responsive: {
            1: {
                item: 1,
            },
            350: {
                items: 2,
            },
            768: {
                items: 4,
            },
            1200: {
                items: 6,
            },
        },
        loop: true,
        dots: false,
        nav: false,
        autoplay: true,
        autoplayTimeout: 6000,
    };

    public images: Array<object> = [
        {
            bg_url: '/assets/smwp_logo.png',
        },
        {
            bg_url: '/assets/msme_logo.png',
        },
        {
            bg_url: '/assets/bmeters_logo.png',
        },
        {
            bg_url: '/assets/smwd_logo.png',
        },
        {
            bg_url: '/assets/rolbud_logo.png',
        },
        {
            bg_url: '/assets/theta_logo.png',
        },
    ];

    ngOnInit() {}
}
