import { Component } from '@angular/core';
import { ContactService } from '../../../services/contact.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
    businessNumber: string;

    constructor(contact: ContactService, private translate: TranslateService) {
        this.businessNumber = contact.getBusinessContactNumber();
    }

    switchLang(lang_code) {
        this.translate.use(lang_code);
    }
}
