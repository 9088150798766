<div class="background-block">
    <div class="center-vert">
        <div class="login-block">
            <app-logo></app-logo>

            <select
                *ngIf="cities"
                [(ngModel)]="city"
                (ngModelChange)="getSettlements(city)"
            >
                <option [value]="null">
                    {{ 'server_selection.select_city' | translate }}
                </option>
                <option *ngFor="let city of cities" [value]="city.id">
                    {{ city.name }}
                </option>
            </select>

            <select
                *ngIf="settlements && settlements.length"
                [(ngModel)]="settlement"
            >
                <option [ngValue]="null">
                    {{ 'server_selection.select_client' | translate }}
                </option>
                <option *ngFor="let item of settlements" [ngValue]="item">
                    {{ item.name }}
                </option>
            </select>

            <h1 *ngIf="settlements && !settlements.length">
                {{ 'server_selection.available_soon' | translate }}
            </h1>

            <h1 *ngIf="settlement && !settlement.active">
                {{ 'server_selection.available_soon' | translate }}
            </h1>

            <div class="flex-button-container">
                <a
                    *ngIf="settlement && settlement.active"
                    href="https://{{ settlement.url }}"
                    target="_blank"
                >
                    {{ 'server_selection.login' | translate }}
                </a>
                <a
                    *ngIf="settlement && settlement.active"
                    href="https://{{ settlement.url }}/rejestracja"
                    target="_blank"
                >
                    {{ 'server_selection.register' | translate }}
                </a>
            </div>
        </div>
    </div>
</div>
