import { Component } from '@angular/core';

@Component({
    selector: 'app-media',
    templateUrl: './media.component.html',
    styleUrls: ['./media.component.scss'],
})
export class MediaComponent {
    public images: Array<object> = [
        {
            url: '/assets/wyborcza.png',
            link:
                'http://wroclaw.wyborcza.pl/wroclaw/7,35771,23445684,osiedlowe-problemy-mozesz-zglosic-przez-aplikacje-na-telefon.html',
            alt: 'Gazeta Wyborcza',
        },
        {
            url: '/assets/administrator24png.png',
            link:
                'http://www.administrator24.info/artykul/id12026,' +
                'narzedzia-do-pracy-zdalnej-dedykowane-wspolnotom-i-spoldzielniom-mieszkaniowym/#imieszkaniec',
            alt: 'Administrator24',
        },
        {
            url: '/assets/logo-pb.jpg',
            link: 'https://www.pb.pl/wroclaw-robi-sie-smart-931060',
            alt: 'Puls Biznesu',
        },
        {
            url: '/assets/tvp.png',
            link:
                'http://wroclaw.tvp.pl/37348976/imieszkaniec-i-problem-z-najblizszej-okolicy-zalatwiony',
            alt: 'TVP3 Wrocław',
        },
        {
            url: '/assets/portal-samorzadowy.png',
            link:
                'http://www.portalsamorzadowy.pl/polityka-i-spoleczenstwo/' +
                'na-wroclawskich-osiedlach-wdrozono-elektroniczny-system-komunikacji-z-mieszkancami,108487.html',
            alt: 'Portal Samorządowy',
        },
        {
            url: '/assets/wroclaw-pl.png',
            link:
                'https://www.wroclaw.pl/biznes/aplikacja-z-wroclawia-podbija-polske',
            alt: 'Wrocław.pl',
        },
        {
            url: '/assets/logo-ram.png',
            link:
                'http://www.radioram.pl/articles/view/34180/FutuRAM-nowe-technologie-w-budynkach-mieszkalnych',
            alt: 'Radio RAM',
        },
        {
            url: '/assets/gazeta.png',
            link: 'https://imieszkaniec.pl/GP281.pdf',
            alt: 'Gazeta',
        },
        {
            url: '/assets/radio-wroclaw.png',
            link:
                'http://www.radiowroclaw.pl/articles/view/77915/Cyfrowa-platforma-na-problemy-mieszkancow-SM-Wroclaw-Poludni',
            alt: 'Radio Wrocłąw',
        },
        {
            url: '/assets/tuwroclaw.png',
            link:
                'https://www.tuwroclaw.com/wiadomosci,duza-wroclawska-spoldzielnia-stworzyla-innowacyjny-' +
                'system-obslugi-mieszkancow,wia5-3273-41406.html',
            alt: 'TuWrocław',
        },
    ];
}
