import { Component } from '@angular/core';
import { ContactService } from '../../../services/contact.service';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
    businessNumber: string;
    displayHelpDesk: boolean;

    constructor(contact: ContactService) {
        this.businessNumber = contact.getBusinessContactNumber();
        this.displayHelpDesk = contact.displayHelpDesk();
    }
}
