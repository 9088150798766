<section>
    <div>
        <h2>
            <app-logo></app-logo>
        </h2>
        <p>{{ 'overall_description_paragraph_1' | translate }}</p>
        <p>{{ 'overall_description_paragraph_2' | translate }}</p>
    </div>
    <div>
        <a
            href="https://www.youtube.com/watch?v=guIOXzXB5Yw"
            target="_blank"
            rel="noopener noreferrer"
        >
            <img
                src="assets/obsluga-zgloszen-nieruchomosci.png"
                alt="System Obslugi Zgłoszeń dla Nieruchomości"
            />
        </a>
    </div>
</section>

<section>
    <div>
        <img src="assets/l3.png" class="l2" />
    </div>
    <div>
        <h2>Skuteczne zarządzanie zgłoszeniami dla spółdzielni i zarządców</h2>
        <p>{{ 'for_housing_association_paragraph_1' | translate }}</p>
        <p>{{ 'for_housing_association_paragraph_2' | translate }}</p>
        <p>{{ 'for_housing_association_paragraph_3' | translate }}</p>
        <p>{{ 'for_housing_association_paragraph_4' | translate }}</p>
        <a href="/" class="btn btn-green">{{ 'write_to_us' | translate }}</a>
    </div>
</section>

<section>
    <div>
        <h2>{{ 'for_tenants_title' | translate }}</h2>
        <p>{{ 'for_tenants_paragraph_1' | translate }}</p>
        <p>{{ 'for_tenants_paragraph_2' | translate }}</p>
        <p>{{ 'for_tenants_paragraph_3' | translate }}</p>

        <div class="badges">
            <a
                href="https://play.google.com/store/apps/details?id=pl.imieszkaniec.app&hl=pl"
                target="_blank"
            >
                <img src="assets/google-play-badge.png" />
            </a>
            <a
                href="https://itunes.apple.com/pl/app/imieszkaniec-pl/id1449974822"
                target="_blank"
            >
                <img src="assets/ios-badge.svg" />
            </a>
        </div>
    </div>
    <div>
        <img src="assets/l2.png" />
    </div>
</section>

<section>
    <div>
        <img src="assets/l4.jpg" class="l4" />
    </div>
    <div>
        <h2>{{ 'experience_title' | translate }}</h2>
        <p>{{ 'experience_paragraph' | translate }}</p>
    </div>
</section>
