<footer>
    <section>
        <ul>
            <li>iMieszkaniec.pl sp. z o.o.</li>
            <li>Kordeckiego 18</li>
            <li>62-800 Kalisz</li>
            <li>{{ 'nip' | translate }}</li>
            <li>{{ 'krs' | translate }}</li>
        </ul>
    </section>
    <section>
        <h4>{{ 'contact' | translate }}</h4>
        <ul>
            <li>
                <a id="email-footer" href="mailto:kontakt@imieszkaniec.pl">
                    kontakt@imieszkaniec.pl
                </a>
            </li>
            <li>
                <div>{{ 'business' | translate }}</div>
            </li>
            <li>
                <a id="phone-footer" href="tel:{{ businessNumber }}">{{
                    businessNumber
                }}</a>
            </li>
            <li *ngIf="displayHelpDesk">
                <div>{{ 'help' | translate }}</div>
            </li>
            <li *ngIf="displayHelpDesk">
                <a
                    id="email-helpdesk-footer"
                    href="mailto:pomoc@imieszkaniec.pl"
                    >pomoc@imieszkaniec.pl</a
                >
            </li>
        </ul>
    </section>
    <section>
        <h4>{{ 'product' | translate }}</h4>
        <ul>
            <li>
                <a href="/">iMieszkaniec.pl</a>
            </li>
            <li>
                <a id="link-iw" href="https://iwykonawca.pl" target="_blank">
                    iWykonawca.pl
                </a>
            </li>
        </ul>
    </section>
</footer>
