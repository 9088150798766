<app-header></app-header>
<main>
    <h1>Usuwanie i zmiana danych</h1>

    <p>
        Jeżeli chcesz zmienić lub usunąć swoje konto, skontaktuj się z
        <a href="mailto:pomoc@imieszkaniec.pl">pomoc@imieszkaniec.pl</a>
    </p>
</main>
<app-footer></app-footer>
