<section id="contactForm">
    <div class="contact-box">
        <div>
            <h2>Skontaktuj się z nami</h2>
            <p>{{ 'write_to_us_description' | translate }}</p>
        </div>
        <div>
            <form>
                <input
                    [(ngModel)]="name"
                    name="company"
                    placeholder="{{ 'entity_name' | translate }}"
                    class="form-element"
                    required
                />
                <input
                    [(ngModel)]="phone"
                    name="phone"
                    type="tel"
                    placeholder="{{ 'phone' | translate }}"
                    class="form-element"
                    required
                />
                <label class="checkbox-label">
                    <input
                        [(ngModel)]="policy"
                        [ngModelOptions]="{ standalone: true }"
                        type="checkbox"
                        required
                    />{{ 'clause_description' | translate }}

                    <a href="/assets/KlauzulaKontaktu.pdf">
                        {{ 'clause' | translate }}
                    </a>
                </label>

                <input
                    id="send-button-footer"
                    class="btn btn-white"
                    type="submit"
                    (click)="onSubmit()"
                    value="Wyślij zapytanie"
                />
            </form>
        </div>
    </div>
</section>
