<section>
    <h2>Co mówią o nas nasi klienci?</h2>

    <div class="reviews">
        <owl-carousel
            [options]="owlOptions"
            [items]="reviews"
            [carouselClasses]="['owl-theme', 'row', 'sliding']"
        >
            <div *ngFor="let review of reviews" class="review">
                <p>{{ review.description }}</p>
                <p class="author">
                    {{ review.author }}
                    <span>{{ review.author_role }}</span>
                </p>
            </div>
        </owl-carousel>
    </div>
</section>
