import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-contact-form',
    templateUrl: './contact-form.component.html',
    styleUrls: ['./contact-form.component.scss'],
})
export class ContactFormComponent {
    name: string;
    phone: string;
    policy: boolean;

    constructor(
        private http: HttpClient,
        private toastr: ToastrService,
        private router: Router,
        private translate: TranslateService
    ) {}

    onSubmit() {
        if (!this.policy) {
            this.toastr.error(
                this.translate.instant('form_error_fill_all_fields')
            );
            return;
        }

        this.http
            .post('/api/contact/', {
                name: this.name,
                phone: this.phone,
                policy: this.policy,
            })
            .subscribe(
                () => {
                    this.router.navigate(['/dziekujemy']);
                    this.name = null;
                    this.phone = null;
                },
                () => {
                    this.toastr.error(
                        this.translate.instant('form_error_invalid')
                    );
                }
            );
    }
}
