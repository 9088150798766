<section>
    <div>
        <h1><span>Zobacz, co dzieje się w&nbsp;</span>Twoich zasobach</h1>
        <p>{{ 'consultation' | translate }}</p>
        <a class="btn btn-border" href="#contactForm">Umów konsultację</a>
    </div>
    <div class="hide-on-mobile">
        <img src="assets/billboard.jpg" alt="Aplikacja mobilna" />
    </div>
</section>
