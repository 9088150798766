import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-apps',
    templateUrl: './apps.component.html',
})
export class AppsComponent implements OnInit {
    isIOS: boolean;

    constructor(private router: Router) {}

    ngOnInit() {
        this.sendUserToCorretShopLink();
    }

    private sendUserToCorretShopLink() {
        if (this.getMobileSystem() === 'Android') {
            window.open(
                'https://play.google.com/store/apps/details?id=pl.imieszkaniec.app&hl=pl'
            );
        } else if (this.getMobileSystem() === 'iOS') {
            window.location.href =
                'https://apps.apple.com/pl/app/imieszkaniec-pl/id1449974822';
        } else {
            this.router.navigate(['/']);
        }
    }

    private getMobileSystem(): string {
        const userAgent = navigator.userAgent || navigator.vendor;

        if (/android/i.test(userAgent)) {
            return 'Android';
        }

        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            return 'iOS';
        }

        return 'unknown';
    }
}
