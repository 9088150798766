import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class HaService {
    constructor(private http: HttpClient) {}

    getCities() {
        return this.http.get('https://api.imieszkaniec.pl/cities/');
    }

    getServers(currentParams: any) {
        return this.http.get(
            'https://api.imieszkaniec.pl/housing_associations/',
            { params: currentParams }
        );
    }
}
