import { Component } from '@angular/core';

@Component({
    selector: 'app-reviews',
    templateUrl: './reviews.component.html',
    styleUrls: ['./reviews.component.scss'],
})
export class ReviewsComponent {
    reviews: Array<{
        author: string;
        description: string;
        author_role: string;
    }> = [
        {
            description:
                // tslint:disable-next-line: max-line-length
                'Nowe narzędzie współtworzyliśmy w celu, między innymi, poszerzenia kanałów komunikacyjnych pomiędzy spółdzielnią a mieszkańcami. Wykorzystanie innowacyjnego, wielokierunkowego systemu przesyłu, przyczyniło się znacząco do usprawnienia obsługi i otworzyło nowe możliwości przekazywania mieszkańcom w czasie rzeczywistym informacji o stanie zgłaszanych spraw. Widzimy potencjał platformy iMieszkaniec.pl i możliwości dalszego rozwoju o kolejne funkcjonalności.',
            author: 'Marek Dera',
            author_role:
                'Prezes Zarządu, Spółdzielnia Mieszkaniowa Wrocław-Południe',
        },
        {
            description:
                // tslint:disable-next-line: max-line-length
                'System zapewnia kontrolę i usprawnia zarządzanie w naszej spółdzielni, zarówno w obszarze zgłoszeń od mieszkańców, jak i wewnętrznej organizacji pracy. Pełna i szczegółowa historia danych umożliwia szybki dostęp do informacji w dowolnym momencie, a statystyka rzeczywisty przegląd poszczególnych budynków. Korzystanie z systemu bardzo ułatwia zarządzanie spółdzielnią.',
            author: 'Maciej Zięba',
            author_role:
                'Prezes Zarządu, Międzyzakładowa Spółdzielnia Mieszkaniowa Energetyk',
        },
        {
            description:
                // tslint:disable-next-line: max-line-length
                'System iMieszkaniec.pl znacznie uprościł sposób przekazywania, gromadzenia, archiwizowania i analizowania zdarzeń. Obsługa techniczna aplikacji jest pomocna i stara się brać pod uwagę indywidualne potrzeby użytkownika. Po kwartale stosowania, mogę śmiało potwierdzić skuteczność aplikacji.',
            author: 'Marcin Steczek',
            author_role: 'SM Prądnik Czerwony',
        },
        {
            description:
                // tslint:disable-next-line: max-line-length
                'Aplikacja iMieszkaniec.pl zdecydowanie ułatwiła nam pracę w zakresie obsługi awarii. Panel jest przejrzysty i oferuje wiele możliwości analitycznych – co przydaje się przy podsumowaniach. Serdecznie polecam!',
            author: 'Dominik Rynarzewski',
            author_role: 'Rynarzewscy Nieruchomości',
        },
        {
            description:
                // tslint:disable-next-line: max-line-length
                'Ogromną zaletą platformy iMieszkaniec.pl jest brak papierowego dziennika oraz to, że każdy w dowolnym momencie z dowolnego urządzenia ma wgląd w etap realizacji zgłoszenia. Niezwykle ułatwia to przepływ informacji między pracownikami Spółdzielni i wykonawcami zewnętrznymi.',
            author: 'Michał Trojnar',
            author_role:
                'Kierownik Gospodarki Zasobami Mieszkaniowymi, SM „Śnieżka” z Krakowa',
        },
        {
            description:
                // tslint:disable-next-line: max-line-length
                'Dzięki aplikacji iMieszkaniec.pl zgłaszanie wszelkiego typu awarii stało się prostsze zarówno dla pracowników, jak i samych wykonawców. Wszystko jest bardzo czytelne i przejrzyste, a co najważniejsze wszystkie informacje skupione są w jednym miejscu, co pomaga w wyszukiwaniu zgłoszeń zrealizowanych w odległych okresach, jak i bieżących.',
            author: 'Marta Góralczyk',
            author_role: 'SM Lokator',
        },
        {
            description:
                // tslint:disable-next-line: max-line-length
                'iMieszkańca polecam każdemu Zarządcy nieruchomości, który chciałby poprawić zarządzanie wszelkiego rodzaju zgłoszeniami. Odkąd zacząłem korzystać z tej aplikacji wszystko mam pod kontrolą. Do tego bardzo intuicyjna obsługa i przystępna cena. Moi klienci również cenią sobie to rozwiązanie. To jest naprawdę dobry wybór.',
            author: 'Jarosław Siedlikowski',
            author_role: 'KIMDOM',
        },
        {
            description:
                // tslint:disable-next-line: max-line-length
                'iMieszkaniec bardzo pomógł mi w pracy, natychmiastowo otrzymuję powiadomienie o usterce i przekazuje ją do realizacji. Do każdego zakończonego zlecenia dołączane są zdjęcia, dzięki czemu mam pewność, że awaria została usunięta.',
            author: 'Tomasz Polok',
            author_role: 'Specjalista ds. technicznych, Platinum Nieruchomości',
        },
        {
            description:
                // tslint:disable-next-line: max-line-length
                'iMieszkaniec ułatwia i przyspiesza proces obsługi zgłoszeń i usuwania awarii oraz umożliwia szybkie powiadamianie Mieszkańców o zaistniałych awariach.',
            author: 'Ireneusz Pobiedziński',
            author_role: 'SM Szóstka',
        },
    ];

    owlOptions: object = {
        responsiveBaseElement: 'body',
        responsive: {
            1: {
                items: 1,
            },
            1400: {
                items: 3,
            },
            1600: {
                items: 4,
            },
        },
        dots: true,
        nav: false,
        autoplay: true,
        autoplayTimeout: 10000,
        margin: 25,
    };
}
