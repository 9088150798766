<section class="webinar">
    <div class="webinar-content">
        <h2>
            Webinar — <b>Jak skutecznie poprowadzić zebranie</b> ze wspólnotą?
        </h2>

        <h4>3. marca 2022 o godzinie 13:00</h4>

        <p>
            Weź udział w webinarze, na którym będziesz mógł porozmawiać z
            ekspertami z branży mieszkaniowej. Bez wychodzenia z domu zyskaj
            wiedzę i praktyczne wskazówki jak organizować i prowadzić zebrania
            ze wspólnotą. Szczególny nacisk położymy na tematy związane z
            organizacją spotkań w czasie pandemii.
        </p>

        <a
            href="https://webinar.imieszkaniec.pl/jak-poprowadzic-spotkanie-ze-wspolnota?source=imieszkaniec"
            target="_blank"
            rel="noopener"
            class="webinar-btn"
            >Dowiedz się więcej</a
        >
    </div>
</section>
