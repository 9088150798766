<app-header></app-header>
<main>
    <h1>Dziękujemy za wysłanie wiadomości</h1>

    <p>Skontaktujemy się z Tobą najszybciej, jak to możliwe.</p>

    <p>
        W międzyczasie zapraszam Cię do polubienia naszej strony na
        <a
            href="https://facebook.com/imieszkaniec"
            target="_blank"
            rel="noopener"
            >Facebooku</a
        >, gdzie na bieżąco wrzucamy informacje o prowadzonych przez nas
        webinarach i spotkaniach ze specjalistami z branży mieszkalnictwa.
    </p>
</main>
<app-footer></app-footer>
