<app-header></app-header>
<main>
    <h1>Polityka prywatności Usługi iWykonawca.pl</h1>

    <section>
        <h4>Spis treści:</h4>
        <ul>
            <li>1-4. Zagadnienia wprowadzające</li>
            <li>5. Przetwarzanie danych osobowych w Usłudze iWykonawca.pl</li>
            <li>
                6. Przetwarzanie danych osobowych Użytkownika przez
                Administratora danych osobowych w koncie Wykonawcy w Usłudze
                iWykonawca.pl
            </li>
            <li>
                7. Przetwarzanie danych osobowych Usługobiorcy (będącego również
                Wykonawcą, Klientem i Użytkownikiem), który jest osobą fizyczną
                lub przedsiębiorcą, będącym "osobą, której dane dotyczą" oraz
                Personelu
            </li>
            <li>
                8. Przetwarzanie danych osobowych Wykonawcy w przypadku
                powiązania Usługi iWykonawca.pl z Usługą iMieszkaniec.pl
            </li>
            <li>9. Informacja o cookies</li>
            <li>10. Definicje pojęć</li>
            <li>11. Zmiany w Polityce prywatności</li>
        </ul>
    </section>

    <section>
        <h4>
            1. Usługa iWykonawca.pl działająca pod adresem
            https://iwykonawca.pl/ świadczona jest przez iMieszkaniec.pl sp. z
            o.o. z siedzibą w Kaliszu, (adres siedziby: 62-800 Kalisz
            Kordeckiego 18), której dokumentacja rejestrowa przechowywana jest w
            Sądzie Rejonowym dla Miasta Poznania, wpisaną do Krajowego Rejestru
            Sądowego pod numerem KRS 0000789320, posiadającą numer REGON
            302680969; NIP 6182145858.
        </h4>
        <h4>
            2. Przetwarzanie danych osobowych w Usłudze iWykonawca.pl, zależnie
            dla określonych procesów przetwarzania, następuje w związku z
            wykonaniem umów (z kontrahentami, podwykonawcami, zatrudnienia,
            itp.), realizacją prawnie uzasadnionych interesów oraz umów
            powierzenia i/lub podpowierzenia przetwarzania danych osobowych na
            zasadach określonych w Polityce Prywatności i Regulaminie.
        </h4>
        <h4>3. W Usłudze iWykonawca.pl Podmiotem zarządzającym jest:</h4>
        <ul>
            <li>
                1) dla Usługobiorcy będącego jednocześnie Wykonawcą i
                Użytkownikiem - Usługodawca, jako Operator, którym jest
                iMieszkaniec.pl sp. z o. o. z siedzibą w Kaliszu,
            </li>

            <li>
                2) dla Użytkownika (innego niż Wykonawca), który zleca obsługę
                lub wykonuje obsługę Zgłoszenia jest Wykonawca (Usługobiorca),
                który dokonuje rejestracji Użytkownika do zarządzanej przez
                niego w ramach posiadanego konta Wykonawcy Usługi iWykonawca.pl.
            </li>
        </ul>
        <h4>
            4. Regulamin korzystania z Usługi iWykonawca.pl (zwany dalej
            Regulamin) i Polityka prywatności dostępne są na stronie na stronie
            https://iwykonawca.pl/logowanie.
        </h4>
        <h4>5. Przetwarzanie danych osobowych w Usłudze iWykonawca.pl</h4>
        <ul>
            <li>
                1) Dane osobowe Użytkowników są przetwarzane z zachowaniem
                środków bezpieczeństwa spełniających wymagania RODO.
            </li>
            <li>
                2) Informacja o Administratorze Danych Osobowych podawana jest w
                trakcie rejestracji Wykonawcy do Usługi oraz w związku z
                aktywacją konta Użytkownika Usługi iWykonawca.pl;
            </li>
            <li>
                3) Użytkownik loguje się do swojego konta w panelu logowania
                https://iwykonawca.pl/logowanie
            </li>
            <li>
                4) Operator (jako Administrator Danych Osobowych - ADO dla
                Wykonawcy) i Wykonawca (jako ADO lub działający w imieniu ADO
                dla Użytkownika) wykorzystując pełną funkcjonalność
                Oprogramowania iWykonawca.pl, przetwarza dane osobowe
                Użytkowników na zasadach indywidualnie ustalonych i podanych
                Użytkownikowi przez właściwego Administratora danych osobowych
                lub na standardowych zasadach określonych w niniejszej Polityce
                prywatności w punktach poniżej oraz Regulaminie Usługi
                iWykonawca.pl.
            </li>
            <li>
                5) Jeżeli indywidualnie określony Wykonawca (jako ADO lub
                działający w imieniu ADO) przedstawi obsługiwanym przez niego
                Użytkownikom odmienne niż w pkt. 6 standardowe cele i zasady
                przetwarzania danych osobowych Użytkowników w Usłudze
                iWykonawca.pl dla osób, których dane osobowe przetwarza w ramach
                Usługi, to te odmienne cele i zasady są wiążące. W takim
                przypadku standardowe cele i zasady podane w pkt. 6 niniejszej
                Polityki prywatności nie mają zastosowania.
            </li>
            <li>
                6) Jeżeli wystąpią jakiekolwiek rozbieżności pomiędzy niniejszą
                Polityką prywatności a Regulaminem usługi iWykonawca.pl, to w
                sprawach przetwarzania danych osobowych i definicjach, wiążące
                są zapisy Polityki prywatności.
            </li>
        </ul>
        <h4>
            6. Przetwarzanie danych osobowych Użytkownika przez Wykonawcę
            wykonywane jako Administrator danych osobowych (ADO) lub w imieniu
            ADO w koncie Wykonawcy w Usłudze iWykonawca.pl, w tym w
            szczególności pracowników i osób zatrudnionych przez Wykonawcę, dane
            osobowe pracowników i osób zatrudnionych przez kontrahentów i
            podwykonawców oraz dane osobowe kontrahentów i podwykonawców,
            będących osobami, których dane dotyczą, a także innych
            autoryzowanych przez Wykonawcę Użytkowników.
        </h4>
        <ul>
            <li>
                1) Administratorem danych osobowych dla kategorii osób
                wymienionych w pkt. 6, w zdaniu powyżej jest Wykonawca lub
                Administrator w imieniu, którego Wykonawca przetwarza dane
                osobowe Użytkownika w Usłudze iWykonawca.pl, w ramach
                posiadanego Konta Wykonawcy, na zasadach określonych w
                niniejszej Polityce prywatności i Regulaminie. Szczegółowe
                informacje o ADO oraz zasadach przetwarzania danych osobowych
                można uzyskać, komunikując się z indywidualnie określonym
                Wykonawcą, który dokonał aktywacji konta Użytkownika lub
                “powiązania Usługi”, i z którym standardowo wiążą Użytkownika, w
                szczególności wcześniej zawarte umowy (o pracę, świadczenie
                usług, współpracę, itp.) inne niż Regulamin Usługi
                iWykonawca.pl. W odniesieniu do Użytkownika lub innej
                autoryzowanej do korzystania z systemu osoby zastosowanie mają
                standardowe informacje podane w pkt. od 2 do 8 poniżej, z
                zastrzeżeniem w ust. 5. pkt. 5) i 6) niniejszej Polityki
                prywatności.
            </li>
            <li>
                2) Dane osobowe Użytkownika lub innej autoryzowanej do
                korzystania z systemu osoby w Usłudze iWykonawca.pl, przy
                wykorzystaniu Oprogramowania iWykonawca.pl, przetwarzane będą
                przez Wykonawcę w celu: a) aktywacji i użytkowania w Usłudze
                iWykonawca.pl: konta Użytkownika, na zasadach określonych w
                Regulaminie, co w szczególności następuje w funkcjonalnym
                związku z wykonywaniem wcześniej zawartej umowy, np. o
                zatrudnienie lub świadczenie usług, współpracy, itp. na
                podstawie art. 6 ust. 1 lit. b) Ogólnego rozporządzenia o
                ochronie danych tj. RODO (wykonanie umowy); b) - usprawnienia
                procesu obsługi Mieszkańca i Zgłoszeń, w szczególności w
                obszarze komunikowania się oraz wymiany informacji, (w tym o
                aktualnym stanie realizacji), dokumentowania i rozliczalności
                art. 6 ust. 1 lit. b) Ogólnego rozporządzenia o ochronie danych
                tj. RODO (wykonywanie umowy) c) - usprawnienia procesu obsługi
                Mieszkańca i Zgłoszeń, w szczególności w obszarze komunikowania
                się oraz wymiany informacji, (w tym o aktualnym stanie
                realizacji), dokumentowania i rozliczalności, realizowanych bez
                akceptacji Regulaminu Usługi iWykonawca.pl lub ponad zakres
                wskazany w Regulaminie (lub innej umowie) zastosowanie ma art. 6
                ust. 1 lit f) Ogólnego rozporządzenia o ochronie danych tj. RODO
                (prawnie uzasadniony interes, jakim jest usprawnienie procesu
                obsługi Zgłoszeń) d) ustalenia i dochodzenia roszczeń lub dla
                obrony przed takimi roszczeniami na podstawie art. 6 ust. 1 lit.
                f) Ogólnego rozporządzenia o ochronie danych tj. RODO (prawnie
                uzasadniony interes Administratora, jakim jest podejmowanie
                czynności związanych z roszczeniami). e) obsługi reklamacji, na
                podstawie art. 6 ust. 1 lit. b) Ogólnego rozporządzenia o
                ochronie danych tj. RODO (wykonanie umowy);
            </li>
            <li>
                3) Odbiorcami danych osobowych Użytkownika zależnie od uprawnień
                nadanych przez Wykonawcę, będą w szczególności: podmioty
                świadczące usługi Administratorowi i/lub Podmiotowi
                przetwarzającemu, osoby zatrudnione przez Administratora i/lub
                Podmiot przetwarzający oraz Mieszkaniec, w związku z wykonywaną
                zleconą obsługą otrzymanego Zgłoszenia, itp. (np. naprawy),
                iMieszkaniec.pl sp. z o.o. (dostawca Oprogramowania
                iWykonawca.pl) oraz jego podwykonawcy w związku ze świadczeniem
                usługi utrzymania i hostingowej oraz obsługą, a także inne
                podmioty upoważnione do odbioru danych na podstawie przepisów
                prawa.
            </li>
            <li>
                4) Dane osobowe Użytkownika nie będą przekazywane do państwa
                trzeciego (tj. państwa nienależącego do Europejskiego Obszaru
                Gospodarczego) i/lub organizacji międzynarodowej.
            </li>
            <li>
                5) Okres przechowywania danych osobowych Użytkownika
                zgromadzonych i przetwarzanych w Oprogramowaniu iWykonawca.pl
                uzależniony jest od celu, dla którego dane są zbierane, zgodnie
                z poniższymi kryteriami: a) przez czas nie dłuższy niż okres
                użytkowania przez Wykonawcę Oprogramowania iWykonawca.pl (w celu
                świadczenia Usługi iWykonawca.pl); b) do momentu usunięcia konta
                Użytkownika na zasadach określonych w Regulaminie; c) do momentu
                złożenia przez Użytkownika sprzeciwu - w przypadku przetwarzania
                danych osobowych na podstawie art. 6 ust. 1 lit. f); d) do
                momentu zakończenia obsługi reklamacji lub rozstrzygnięcia sporu
                / rozliczenia stron, z uwzględnieniem właściwych terminów
                przedawnienia roszczeń – w przypadku przetwarzania danych w celu
                dochodzenia roszczeń i podejmowania działań o charakterze
                windykacyjnym. Po wskazanych wyżej okresach, dane osobowe
                przechowywane będą przez czas, jaki właściwe przepisy prawa
                nakazują przechowanie danych lub przez okres przedawnienia
                ewentualnych roszczeń.
            </li>
            <li>
                6) JW związku z przetwarzaniem danych osobowych, Przysługują
                Użytkownikowi następujące prawa: a) prawo dostępu do treści
                danych, prawo do sprostowania danych, prawo do usunięcia danych,
                prawo do ograniczenia przetwarzania danych, prawo do
                przenoszenia danych, b) prawo do sprzeciwu wobec przetwarzania
                danych - w przypadku, gdy przetwarzanie danych osobowych odbywa
                się na podstawie tzw. prawnie uzasadnionego interesu i na
                warunkach określonych w przepisach Ogólnego rozporządzenia o
                ochronie danych, c) prawo do wniesienia skargi do organu
                nadzorczego (Prezesa Urzędu Ochrony Danych Osobowych) w
                przypadku uznania, że przetwarzanie danych osobowych narusza
                przepisy Ogólnego rozporządzenia o ochronie danych.
            </li>
            <li>
                7) Dane osobowe Użytkownika nie podlegają zautomatyzowanemu
                podejmowaniu decyzji, w tym profilowaniu.
            </li>
            <li>
                8) Zgoda Użytkownika na utworzenie jego konta i akceptacja
                Regulaminu Usługi jest niezbędna do świadczenia usługi
                iWykonawca.pl i nie jest tożsama ze zgodą w rozumieniu art. 6
                ust. 1 lit. a) RODO na przetwarzanie danych osobowych. W
                przypadku braku akceptacji na korzystanie z tej Usługi w tym
                przetwarzanie danych osobowych Użytkownika nie będzie możliwe.
            </li>
        </ul>
        <h4>
            7. Przetwarzanie danych osobowych Usługobiorcy (będącego również
            Wykonawcą, Klientem i Użytkownikiem), który jest osobą fizyczną lub
            przedsiębiorcą, będącym "osobą, której dane dotyczą" oraz Personelu
            Usługobiorcy.
        </h4>
        <ul>
            <li>
                1) Administratorem danych osobowych Usługobiorcy w związku z
                zawarciem, wykonywaniem umowy w formie akceptacji Regulaminu o
                użytkowanie Oprogramowania iWykonawca.pl, który jest osobą
                fizyczną lub przedsiębiorcą, będącym osobą, której dane dotyczą
                oraz osób, którymi dowolny Usługobiorca ewentualnie będzie się
                posługiwać w czasie i w celu zawierania lub wykonywania umowy o
                użytkowanie Oprogramowania iWykonawca.pl (przedstawicieli swoich
                i swoich podwykonawców, pracowników swoich, jak i innych osób)
                dalej zwanych Personelem, jest iMieszkaniec.pl sp. z o.o., ul.
                Kordeckiego 18, 62-800 Kalisz, KRS: 0000789320. Usługobiorca i
                jeśli ma zastosowanie Personel Usługobiorcy będą dalej łącznie
                określani, jako: Usługobiorca.
            </li>
            <li>
                2) Dane kontaktowe Administratora danych osobowych Usługobiorcy
                stanowią adres email: kontakt@iwykonawca.pl.
            </li>
            <li>
                3) Administrator danych osobowych Usługobiorcy jest jednocześnie
                Usługodawcą udostępniającym na podstawie umowy o użytkowanie
                Oprogramowania iWykonawca.pl w celu umożliwienia Usługobiorcy,
                jako Wykonawcy świadczenia Usługi iWykonawca.pl w ramach
                posiadanego konta Wykonawcy.
            </li>
            <li>
                4) Dane osobowe Usługobiorcy przetwarzane będą w celu: a)
                zawarcia i wykonywania umowy, o udostępnienie do użytkowania
                Oprogramowania iWykonawca.pl (....) na podstawie art. 6 ust. 1
                lit. b) Ogólnego rozporządzenia o ochronie danych tj. RODO
                (wykonanie umowy); b) obsługi Usługobiorcy w związku z
                użytkowaniem Oprogramowania iMiszkaniec.pl oraz świadczeniem
                przez Usługobiorcę jako Wykonawca Usługi iWykonawca.pl, w
                szczególności w sprawach reklamacji, usuwania kont Użytkowników,
                itp. na podstawie art. 6 ust. 1 lit. b) Ogólnego rozporządzenia
                o ochronie danych tj. RODO (wykonanie umowy); c) obsługi w
                związku z aktywowaniem konta przez Usługobiorcę, jako
                Użytkownika w ramach Usługi iWykonawca.pl, na zasadach
                określonych w jej Regulaminie, na podstawie art. 6 ust. 1 lit.
                b) Ogólnego rozporządzenia o ochronie danych tj. RODO (wykonanie
                umowy), d) komunikacji w zakresie realizacji umowy oraz
                prezentowania aktualnej oferty produktowej na podstawie art. 6
                ust. 1 lit. f) Ogólnego rozporządzenia o ochronie danych tj.
                RODO (obsługa Usługobiorcy) e) obsługi reklamacji, na podstawie
                art. 6 ust. 1 lit. b) Ogólnego rozporządzenia o ochronie danych
                tj. RODO (wykonanie umowy); f) wypełniania obowiązków prawnych
                ciążących na Administratorze danych osobowych Usługobiorcy, w
                szczególności wynikających z powszechnie obowiązujących
                przepisów prawa o rachunkowości i podatkowych art. 6 ust. 1 lit.
                c) Ogólnego rozporządzenia o ochronie danych tj. RODO
                (wypełnienie obowiązku prawnego), g) ustalenia i dochodzenia
                roszczeń lub dla obrony przed takimi roszczeniami na podstawie
                art. 6 ust. 1 lit. f) Ogólnego rozporządzenia o ochronie danych
                tj. RODO (prawnie uzasadniony interes Administratora, jakim jest
                podejmowanie czynności związanych z roszczeniami).
            </li>
            <li>
                5) Odbiorcami danych osobowych Usługobiorcy, którego dane
                dotyczą będą w szczególności podmioty przetwarzające w związku z
                obsługą finansową, komunikacją oraz zarządzaniem relacjami (CRM)
                z Usługobiorcą, podwykonawcy iMieszkaniec.pl sp. z o.o. w
                zakresie świadczonych usług hostingowych i utrzymania, a także
                podmioty wskazane przez Wykonawcę Usługi iWykonawca.pl w związku
                ze świadczeniem przez niego Usługi iWykonawca.pl oraz podmioty
                upoważnione do odbioru danych na podstawie umów lub przepisów
                prawa.
            </li>
            <li>
                6) Dane osobowe Usługobiorcy nie będą przekazywane do państwa
                trzeciego (tj. państwa nienależącego do Europejskiego Obszaru
                Gospodarczego) i/lub organizacji międzynarodowej.
            </li>
            <li>
                7) Okres przechowywania danych osobowych Usługobiorcy
                uzależniony jest od celu, dla którego dane są zbierane, zgodnie
                z poniższymi kryteriami: a) przez czas nie dłuższy niż okres
                wykonywania umowy o udostępnienie do użytkowania Oprogramowania
                iWykonawca.pl; b) do momentu złożenia przez Usługobiorcę
                sprzeciwu - w przypadku przetwarzania danych osobowych na
                podstawie art. art. 6 ust. 1 lit. f); c) do momentu usunięcia
                konta Usługobiorcy, jako Użytkownika Usługi iWykonawca.pl
                (jeżeli takie konto aktywował i użytkował); d) do momentu
                obsługi reklamacji lub rozstrzygnięcia sporu / rozliczenia
                stron, z uwzględnieniem właściwych terminów przedawnienia
                roszczeń – w przypadku przetwarzania danych w celu dochodzenia
                roszczeń i podejmowania działań o charakterze windykacyjnym Po
                wskazanych wyżej okresach, dane osobowe przechowywane będą przez
                czas, jaki właściwe przepisy prawa nakazują przechowanie danych
                lub przez okres przedawnienia ewentualnych roszczeń.
            </li>
            <li>
                8) W związku z przetwarzaniem danych osobowych Usługobiorcy
                przysługują następujące prawa: a) prawo dostępu do treści
                danych, prawo do sprostowania danych, prawo do usunięcia danych,
                prawo do ograniczenia przetwarzania danych, prawo do
                przenoszenia danych, b) prawo do sprzeciwu wobec przetwarzania
                danych - w przypadku, gdy przetwarzanie danych osobowych odbywa
                się na podstawie tzw. prawnie uzasadnionego interesu i na
                warunkach określonych w przepisach Ogólnego rozporządzenia o
                ochronie danych, c) prawo do wniesienia skargi do organu
                nadzorczego (Prezesa Urzędu Ochrony Danych Osobowych) w
                przypadku uznania, że przetwarzanie danych osobowych narusza
                przepisy Ogólnego rozporządzenia o ochronie danych.
            </li>
            <li>
                9) Dane osobowe Usługobiorcy nie podlegają zautomatyzowanemu
                podejmowaniu decyzji, w tym profilowaniu.
            </li>
            <li>
                10) Podanie przez Usługobiorcę danych osobowych jest niezbędne
                do świadczenia usługi iWykonawca.pl, w przypadku niepodania
                danych niemożliwe jest korzystanie z tej Usługi.
            </li>
        </ul>
        <h4>
            8. Przetwarzanie danych osobowych Wykonawcy w przypadku powiązania
            Usługi iWykonawca.pl z Usługą iMieszkaniec.pl
        </h4>
        <ul>
            <li>
                1) Udostępnianie oraz przetwarzanie danych i danych osobowych
                zawartych w Zgłoszeniu oraz danych dodanych do niego w związku z
                jego obsługą, jest realizowana między określonymi operatorem
                Usługi iMieszkaniec.pl i powiązanym Wykonawcą korzystającym z
                Usługi iWykonawca.pl, na podstawie umów zawartych między
                wymienionymi stronami i/lub prawnie uzasadnionym interesem
                Administratora.
            </li>
            <li>
                2) Dane osobowe Wykonawcy, będącego osobą, której dane dotyczą,
                w powiązanych usługach, są rejestrowane i przetwarzane przez
                określonego Administratora w Usłudze iMieszkaniec.pl w takim
                samym rodzaju i zakresie jak wskazane w rdz. III ust. 7 lit.b
                i-iii Regulaminu.
            </li>
            <li>
                3) Indywidualnie określony Administrator lub podmiot działający
                w imieniu Administratora, przetwarza dane osobowe Wykonawcy w
                powiązanej Usłudze iMieszkaniec.pl, jako jej Operator, z którym
                uprzednio wiążą Wykonawcę, w szczególności zawarta umowa
                zatrudnienia, umowa o świadczenie usług i inne. Przetwarzanie
                danych osobowych Wykonawcy Usługi iWykonawca.pl w powiązanej
                Usłudze iMieszkaniec.pl, w zakresie określonym dla Wykonawcy
                zewnętrznego realizowane będzie w sposób ustalony przez
                wskazanego Operatora i na zasadach określonych w Polityce
                Prywatności Usługi iMieszkaniec.pl.
            </li>
        </ul>
        <h4>9. Informacja o cookies</h4>
        <ul>
            <li>
                1) Serwis Usługodawcy używa plików „ cookies” . Brak zmiany po
                stronie Usługobiorcy ustawień przeglądarki jest równoznaczny z
                wyrażeniem zgody na ich użycie .
            </li>
            <li>
                2) Instalacja plików „ cookies ” jest konieczna do prawidłowego
                świadczenia usług w Serwisie. W plikach „ cookies " znajdują się
                informacje niezbędne do prawidłowego funkcjonowania Serwisu, w
                szczególności tych wymagających autoryzacji.
            </li>
            <li>
                3) W ramach Serwisu stosowane pliki „ cookies” analityczne. „
                Cookies ” analityczne umożliwiają lepsze poznanie sposobu
                interakcji Usługobiorcy w zakresie zawartości Serwisu, lepiej
                zorganizować jego układ. “Analityczne” „ cookies ” gromadzą
                informacje o sposobie korzystania z Serwisu przez Usługobiorców,
                typie strony, z jakiej Usługobiorca został przekierowany, oraz
                liczbie odwiedzin i czasie wizyty Usługobiorcy na stronie
                serwisu. Informacje te nie rejestrują konkretnych danych
                osobowych Usługobiorcy, lecz służą do opracowania statystyk
                korzystania z Serwisu.
            </li>
            <li>
                4) W ramach serwisu stosowane są pliki „cookies” związane z
                analityką, pochodzące od Google, z którego usług serwis
                korzysta. Z uwagi na fakt, że sposób działania niektórych plików
                „cookies” pochodzących od partnera zewnętrznego może być inny,
                niż przedstawiony w niniejszej polityce, zalecane jest
                zapoznanie się z informacjami na temat tych plików „cookies”
                dostępnymi na stronach Google:
                https://www.google.pl/intl/pl/policies/technologies/cookies/
            </li>
            <li>
                5) Użytkownik ma prawo zadecydowania w zakresie dostępu plików „
                cookies ” do swojego komputera poprzez ich uprzedni wybór w
                oknie swojej przeglądarki. Szczegółowe informacje o możliwości i
                sposobach obsługi plików„ cookies ” dostępne są w ustawieniach
                oprogramowania (przeglądarki internetowej).
            </li>
        </ul>
        <h4>10. Definicje pojęć</h4>
        <ul>
            <li>
                1) Usługa iWykonawca.pl - działanie podejmowane przez Operatora,
                przy wykorzystaniu Oprogramowania iWykonawca.pl, poprzez stronę
                internetową https://iwykonawca.pl lub aplikację mobilną, w celu
                zaspokojenia potrzeb komunikacyjnych Wykonawców w zakresie
                obsługi Zgłoszeń (związanych np. z zaistnieniem i usuwaniem
                awarii) oraz ich dokumentowania. Zgłoszenia te mogą być
                otrzymywane są od spółdzielni, wspólnot mieszkaniowych,
                zarządców nieruchomości i innych podmiotów korzystających z
                Usługi iMieszkaniec.pl w powiązaniu z Usługą iWykonawca.pl oraz
                spółdzielni, wspólnot mieszkaniowych, zarządców nieruchomości i
                innych podmiotów, które nie korzystają z Usługi iMieszkaniec.pl
                w ramach udostępnionych przez Wykonawcę Kont Użytkowników.
            </li>
            <li>
                2) Oprogramowanie iWykonawca.pl – system informatyczny
                udostępniony przez iMieszkaniec.pl sp. z o.o. w celu
                umożliwienia korzystania z Usługi iWykonawca.pl w formie usługi
                SaaS za pośrednictwem przeglądarki internetowej lub aplikacji
                mobilnej.
            </li>
            <li>
                3) Usługa iMieszkaniec.pl - działanie podejmowane przez
                spółdzielnię, wspólnotę mieszkaniową lub zarządcę nieruchomości,
                przy wykorzystaniu Oprogramowania iMieszkaniec.pl, poprzez
                stronę internetową lub aplikację mobilną, szczególnie w celu
                zaspokojenia określonej potrzeby Mieszkańca. Usługa zapewnia
                komunikację Użytkowników poprzez wysyłanie, odbieranie,
                rejestrowanie i przetwarzanie danych za pomocą systemów
                teleinformatycznych, o sprawach, zdarzeniach, zagrożeniach,
                problemach (np.: awarie, ograniczenia dostaw mediów, planowane
                zmiany w świadczeniu usług), głosowaniach, itp..
            </li>
            <li>
                4) Oprogramowanie iMieszkaniec.pl – system informatyczny
                udostępniony przez iMieszkaniec.pl sp. z o. o. w celu
                umożliwienia korzystania z Usługi iMieszkaniec.pl w formie
                usługi SaaS za pośrednictwem przeglądarki internetowej lub
                aplikacji mobilnej.
            </li>
            <li>
                5) Regulamin - Regulamin Usługi iWykonawca.pl, dostępny w
                zakładce logowania się do Usługi na stronie iwykonawca.pl.
            </li>
            <li>
                6) SaaS - („Software as a Service” czyli „Oprogramowanie jako
                usługa”) usługa, która polega na zdalnym udostępnianiu
                oprogramowania poprzez Internet. Umożliwia ona interakcję z
                systemem informatycznym poprzez interfejs przeglądarki
                internetowej lub aplikacji mobilnej.
            </li>
            <li>
                7) Usługodawca, Operator – iMieszkaniec.pl sp. z o.o. z siedzibą
                w Kaliszu, (adres siedziby: 62-800 Kalisz Kordeckiego 18),
                której dokumentacja rejestrowa przechowywana jest w Sądzie
                Rejonowym dla Miasta Poznania, wpisaną do Krajowego Rejestru
                Sądowego pod numerem KRS 0000789320, posiadającą nr REGON
                302680969; NIP 6182145858
            </li>
            <li>
                8) Wykonawca, Usługobiorca – przedsiębiorca lub osoba prawna
                albo jednostka organizacyjna nie posiadająca osobowości prawnej,
                który obsługuje spółdzielnie i wspólnoty mieszkaniowe, zarządców
                nieruchomości lub inne właściwe podmioty i w związku z tą
                działalnością utworzył Konto Wykonawcy w Usłudze iWykonawca.pl.
            </li>
            <li>
                9) Użytkownik - Wykonawca oraz inna osoba zalogowana do Usługi
                iWykonawca.pl, która ma założone i zatwierdzone Konto
                odpowiednio: przez Operatora dla Wykonawcy i przez Wykonawcę dla
                pozostałych Użytkowników. Użytkownikiem może być w szczególności
                Wykonawca, osoba zatrudniona przez Wykonawcę, kontrahent i
                podwykonawca Wykonawcy oraz osoby przez nich zatrudnione lub
                inny podmiot, który w sposób autoryzowany korzysta z usługi, po
                rejestracji do Usługi iWykonawca.pl.
            </li>
            <li>
                10) Mieszkaniec – osoba fizyczna, w tym także prowadzący
                działalność gospodarczą przedsiębiorca, na rzecz której
                kontrahent Wykonawcy, w szczególności: Spółdzielnia, Wspólnota
                mieszkaniowa, zarządca nieruchomości lub inny właściwy podmiot
                świadczy usługi zarządzania nieruchomościami, serwisowe, itp.
            </li>
            <li>
                11) RODO - Rozporządzenie Parlamentu Europejskiego i Rady (UE)
                2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób
                fizycznych w związku z przetwarzaniem danych osobowych i w
                sprawie swobodnego przepływu takich danych oraz uchylenia
                dyrektywy 95/46/WE (ogólne rozporządzenie o ochronie danych).
            </li>
            <li>
                12) Administrator danych osobowych / Administrator - oznacza
                osobę fizyczną lub prawną, organ publiczny, jednostkę lub inny
                podmiot, który samodzielnie lub wspólnie z innymi ustala cele i
                sposoby przetwarzania danych osobowych,
            </li>
            <li>
                13) Dane osobowe - dane osobowe w rozumieniu RODO – informacje o
                zidentyfikowanej lub możliwej do zidentyfikowania osobie
                fizycznej („osobie, której dane dotyczą”).
            </li>
            <li>
                14) Przetwarzanie danych osobowych – w rozumieniu RODO
                „przetwarzanie” oznacza operację lub zestaw operacji
                wykonywanych na danych osobowych lub zestawach danych osobowych
                w sposób zautomatyzowany lub niezautomatyzowany, taką jak
                zbieranie, utrwalanie, organizowanie, porządkowanie,
                przechowywanie, adaptowanie lub modyfikowanie, pobieranie,
                przeglądanie, wykorzystywanie, ujawnianie poprzez przesłanie,
                rozpowszechnianie lub innego rodzaju udostępnianie,
                dopasowywanie lub łączenie, ograniczanie, usuwanie lub
                niszczenie.
            </li>
            <li>
                15) Podmiot przetwarzający - osoba fizyczna lub prawna, organ
                publiczny, jednostka organizacyjna lub inny podmiot, który
                przetwarza dane osobowe w imieniu Administratora.
            </li>
            <li>
                16) Cookies – tzw. ciasteczka, czyli pliki tekstowe zapisywane
                automatycznie na dysku lokalnym komputera przez Przeglądarkę
                internetową i przechowujące informacje dotyczące odwiedzin takie
                jak np. typ Przeglądarki internetowej, itp..
            </li>
            <li>
                17) Zgłoszenie - zarejestrowanie w iWykonawca.pl zdarzenie (o
                zaistnieniu, zleceniu i obsłudze np. awarii) w formie
                elektronicznej, którego dane przetwarzane są w Usłudze
                iWykonawca.pl, zgodnie z jej funkcjonalnością, potrzebami i
                właściwością Użytkownika oraz w możliwym powiązaniu z Usługą
                iMieszkaniec.pl.
            </li>
        </ul>
        <h4>11. Zmiany w Polityce prywatności.</h4>
        <p>
            W celu zapewnienia, iż Polityka prywatności Portalu spełnia przez
            cały czas aktualne wymogi nałożone w przepisach prawa, Administrator
            techniczny zastrzega sobie prawo do wnoszenia do niej zmian w
            dowolnym czasie. Powyższe ma również zastosowanie w przypadkach, gdy
            Polityka prywatności wymaga zmian w związku z wprowadzeniem nowych
            lub zmienionych produktów albo usług. Polityka Prywatności będzie
            obowiązywała w nowym brzmieniu od dnia powiadomienia o jej zmianie
            poprzez zamieszczenie i udostępnienie Polityki prywatności w
            zaktualizowanej wersji na stronie logowania / rejestracji do Usługi
            iWykonawca.pl.
        </p>
    </section>
</main>
<app-footer></app-footer>
