import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Component({
    selector: 'app-cookies-policy',
    templateUrl: './cookies-policy.component.html',
    styleUrls: ['./cookies-policy.component.scss'],
})
export class CookiesPolicyComponent implements OnInit {
    public isCookieAccepted: boolean;

    constructor(private _cookieService: CookieService) {}

    ngOnInit() {
        if (this.getCookie('iM_web_cookie_accept')) {
            this.isCookieAccepted = true;
        }
    }

    public closeAndSaveCookie(): void {
        if (!this.getCookie('iM_web_cookie_accept')) {
            this.putCookie('iM_web_cookie_accept', 'true');
            this.isCookieAccepted = true;
        }
    }

    private getCookie(key: string): boolean {
        if (this._cookieService.get(key)) {
            return true;
        } else {
            return false;
        }
    }

    private putCookie(key: string, value: string): void {
        this._cookieService.set(key, value);
    }
}
