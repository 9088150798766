<div class="cookies-policy" *ngIf="!isCookieAccepted">
    <div class="left-side">
        {{ 'cookies_description' | translate }}
        <a href="assets/polityka.pdf" target="_blank">
            {{ 'cookies_policy' | translate }}
        </a>
        .
    </div>

    <div class="right-side">
        <a class="btn-close" (click)="closeAndSaveCookie()">
            {{ 'close' | translate }}
        </a>
    </div>
</div>
