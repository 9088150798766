import { Component, OnInit } from '@angular/core';
import { HaService } from '../../services/ha.service';

@Component({
    selector: 'app-settlements',
    templateUrl: './settlements.component.html',
    styleUrls: ['./settlements.component.scss'],
})
export class SettlementsComponent implements OnInit {
    cities: any;
    city: any;
    settlements: any;
    settlement: any;

    constructor(private haService: HaService) {}

    ngOnInit() {
        this.getCities();
    }

    getSettlements(id) {
        if (id !== 'null') {
            this.haService.getServers({ city: id }).subscribe((data: any) => {
                this.settlements = data.results;
            });
        } else {
            this.settlements = null;
        }

        this.settlement = null;
    }

    showConsole(item) {
        console.log(item);
    }

    private getCities() {
        this.haService.getCities().subscribe((data: any) => {
            this.cities = data.results;
            this.city = null;
        });
    }
}
