<section>
    <owl-carousel
        [options]="options"
        [items]="images"
        [carouselClasses]="['owl-theme', 'row', 'sliding']"
    >
        <div class="item" *ngFor="let image of images">
            <img src="{{ image.bg_url }}" />
        </div>
    </owl-carousel>
</section>
