import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateModule } from '@ngx-translate/core';
import { ToastrModule } from 'ngx-toastr';
import { OwlModule } from 'ng2-owl-carousel';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { HaService } from './services/ha.service';
import { CookieService } from 'ngx-cookie-service';
import { AppComponent } from './app.component';
import { LandingPageComponent } from './components/landing-page/landing-page.component';
import { ContentComponent } from './components/landing-page/content/content.component';
import { ReviewsComponent } from './components/landing-page/reviews/reviews.component';
import { FooterComponent } from './components/landing-page/footer/footer.component';
import { ContactFormComponent } from './components/landing-page/contact-form/contact-form.component';
import { OurClientsComponent } from './components/landing-page/our-clients/our-clients.component';
import { HeaderComponent } from './components/landing-page/header/header.component';
import { SettlementsComponent } from './components/settlements/settlements.component';
import { MediaComponent } from './components/landing-page/media/media.component';
import { CookiesPolicyComponent } from './components/cookies-policy/cookies-policy.component';
import { AllClientsComponent } from './components/landing-page/all-clients/all-clients.component';
import { CallUsComponent } from './components/landing-page/call-us/call-us.component';
import { AppsComponent } from './components/apps/apps.component';

import { translateLoaderProvider } from './translate-loader.provider';
import { LogoComponent } from './components/logo/logo.component';
import { ContactService } from './services/contact.service';
import { WebinarComponent } from './components/landing-page/webinar/webinar.component';
import { ThankYouComponent } from './components/thank-you/thank-you.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { PrivacyPolicyIwComponent } from './components/privacy-policy-iw/privacy-policy.component';
import { RemoveDataComponent } from './components/remove-data/remove-data.component';

const appRoutes: Routes = [
    {
        path: 'login',
        component: SettlementsComponent,
    },
    {
        path: 'aplikacja',
        component: AppsComponent,
    },
    {
        path: 'dziekujemy',
        component: ThankYouComponent,
    },
    {
        path: 'polityka-prywatnosci',
        component: PrivacyPolicyComponent,
    },
    {
        path: 'polityka-prywatnosci-iw',
        component: PrivacyPolicyIwComponent,
    },
    {
        path: 'usuwanie-danych',
        component: RemoveDataComponent,
    },
    {
        path: '',
        component: LandingPageComponent,
    },
    {
        path: '**',
        redirectTo: '',
    },
];

@NgModule({
    declarations: [
        AppComponent,
        LandingPageComponent,
        ContentComponent,
        ReviewsComponent,
        FooterComponent,
        ContactFormComponent,
        OurClientsComponent,
        HeaderComponent,
        SettlementsComponent,
        MediaComponent,
        CookiesPolicyComponent,
        AllClientsComponent,
        CallUsComponent,
        AppsComponent,
        LogoComponent,
        WebinarComponent,
        ThankYouComponent,
        PrivacyPolicyComponent,
        PrivacyPolicyIwComponent,
        RemoveDataComponent,
    ],
    imports: [
        RouterModule.forRoot(appRoutes),
        BrowserModule,
        BrowserAnimationsModule,
        ToastrModule.forRoot(),
        TranslateModule.forRoot({
            loader: translateLoaderProvider,
        }),
        OwlModule,
        FormsModule,
        HttpClientModule,
    ],
    providers: [HaService, ContactService, CookieService],
    bootstrap: [AppComponent],
})
export class AppModule {}
