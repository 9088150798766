import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-landing-page',
    templateUrl: './landing-page.component.html',
    styleUrls: ['./landing-page.component.scss'],
})
export class LandingPageComponent implements OnInit {
    constructor() {}

    ngOnInit() {
        window['dataLayer'] = window['dataLayer'] || [];
        window['dataLayer'].push({ event: 'optimize.activate' });
    }
}
